const uk_UA_in_risks_page = {
  risks: 'Ризики',
  of_using_ref_finance: 'використання Ref. фінанси',
  introduction:
    'Надання ліквідності та/або торгівля на Ref Finance не обходяться без ризиків. Перш ніж взаємодіяти з протоколом, будь ласка, проведіть дослідження та проаналізуйте усі ризики.',
  general_risks_quiz: 'Вікторина про загальні ризики',
  amm_core_design: 'Основний дизайн AMM',
  audits: 'Аудити',
  audits_paragraph_2:
    'Аудит безпеки не усуває ризики повністю. Будь ласка, не надавайте Ref Finance свої заощадження або активи, які ви не можете дозволити собі втратити, особливо як постачальника ліквідності.',
  admin_keys: 'Адмін ключі',
  admin_keys_paragraph_2:
    'На даний час DAO налічує 26 членів. Ref Finance переходить до повністю децентралізованого DAO.',
  admin_keys_paragraph_3:
    'Нижче наведено список контрактів та адрес, які безпосередньо керували або керують справами Ref Finance в даний час.',
  address: 'Адреса контракту ',
  type: 'Тип',
  mission: 'Місія',
  exchange_contract: 'Exchange Contract',
  table_body_tr_1:
    'Керування функціями автоматизованого маркетмейкера; Обмін та надання ліквідності',
  farming_contract: 'Farming Contract',
  table_body_tr_2: 'Керування стимулами ліквідності',
  staking_contract: 'Staking Contract',
  table_body_tr_3:
    'Мінт та спалювання xREF, а також розподілення нагород за часом',
  sputnik_dao_contract: 'Sputnik DAO Contract',
  table_body_tr_4:
    'Забезпечення успіху Ref, приймаючи стратегічні рішення (включаючи зміни до смарт-контракту)',
  table_body_tr_5:
    'Керування та розподілення коштів певним учасникам спільноти',
  table_body_tr_6: 'Реалізація стратегії та дорожньої карти',
  vesting_contract: 'Vesting Contract',
  table_body_tr_7: 'Керування контрактами щодо передачі REF членам Dev DAO',
  airdrop_contract: 'Airdrop Contract',
  table_body_tr_8: 'Керування перший аірдропом REF',
  near_address: 'NEAR Address',
  table_body_tr_9:
    'Керування одноразовими виплатами за виправлення помилок (bug bounty)',
  fungible_token_contract: 'Fungible Token Contract',
  table_body_tr_10: 'Випуск токена REF ',
  rug_pull: 'Rug pull',
  rug_pull_paragraph_1:
    'Якщо команда, яка стоїть за токеном, внесеним до білого списку чи ні, вирішить відмовитися від свого проекту і забере гроші інвесторів, токен проекту, ймовірно, буде коштувати 0 доларів.',
  rug_pull_paragraph_2:
    'Якщо токен внесено до білого списку Ref Finance, це не означає, що проект буде успішним. Ви несете відповідальність за проведення власної належної перевірки проекту і повинні розуміти, що криптовалюта є спекулятивними інвестиціями з дуже високим ризиком.',
  rug_pull_paragraph_3:
    'Ви повинні бути обізнані та готові до можливої втрати частини або усіх вкладених коштів.',
  divergence_loss: 'Дивергентні втрати',
  divergence_loss_paragraph_1:
    'Якщо ви надаєте ліквідність, зверніть увагу, що ви можете заробити більше грошей, не надавши ліквідність.',
  divergence_loss_paragraph_2:
    'Дивергенті втрати часто, однак, неправомірно називають «непостійними втратами». Прикметник (непостійний) може припускати або створювати маркетингове відчуття, що втрати є лише тимчасовими, та що вони гарантовано будуть повернуті, що не відповідає дійсності.',
  divergence_loss_paragraph_3: 'Дізнайтеся більше про дивергентні втрати',
  staking_risks: 'Ризики стейкінгу',
  staking_risks_paragraph_1:
    'Під час стейкингу ви використовуєте декілька смарт-контрактів, кожен з яких має свої ризики.',
  permanent_loss_of_a_peg: 'Перманентна втрата пегу',
  permanent_loss_of_a_peg_paragraph_1:
    'Якщо один із стейблкойнів у пулі значно опуститься нижче прив’язки 1,0 і ніколи не повернеться до прив’язки, це фактично означатиме, що провайдери пулу тримають майже всю свою ліквідність у цій валюті.',
  systemic_issues: 'Системні проблеми',
  systemic_issues_paragraph_1:
    'Загалом, DeFi або Legos of money дуже пов’язані, а це означає, що один збій його компонента може спровокувати низку невдач.',
  systemic_issues_paragraph_2:
    'Систематичний ризик означає, що ви можете втратити гроші, навіть якщо збій безпосередньо не стосується ваших інвестицій/експозицій.',
  systemic_issues_paragraph_3:
    'На пули ліквідності можуть вплинути такі ризики:',
  systemic_issues_paragraph_4:
    'Проблеми смарт-контракту з протоколами кредитування',
  systemic_issues_paragraph_5:
    'Проблеми смарт-контракту з протоколами стейкінгу',
  systemic_issues_paragraph_6: 'Системні проблеми зі стейблкоінами в пулах',
  systemic_issues_paragraph_7:
    'Системні проблеми з нативними токенами ERC20 в пулах',
  crypto_trading_addiction: 'Залежність від криптоторгівлі',
  crypto_trading_addiction_paragraph_1:
    'Торгівля криптовалютою може викликати сильне звикання і, за багатьма джерелами, бути формою залежності від азартних ігор, яка може зруйнувати життя.',
  crypto_trading_addiction_paragraph_2:
    'Нижче наведено низку історій, пов’язаних із цим питанням.',
  crypto_trading_addiction_paragraph_3:
    "'Трейдинг — це азартна гра, без сумніву'",
  crypto_trading_addiction_paragraph_4:
    "'Я втратив півмільйона фунтів на торгівлі біткойнами'",
  crypto_trading_addiction_paragraph_5:
    "'Ми поговорили з терапевтом, який лікує залежність від торгівлі криптовалютами'",
  crypto_trading_addiction_paragraph_6:
    "'Я втратив мільйони через залежність від торгівлі криптовалютою'",
  no_in_progress: 'No (In Progress)',
  yes: 'Yes',
  no: 'No',
  NA: 'N/A',
  ref_finance_near: 'ref-finance.near',
  ref_finance_near_mission:
    'v1 (depreciated)- Manage the Automated Market Maker functions; Swap and Provide Liquidity',
  token_ref_finance_near: 'token.ref-finance.near',
  token_ref_finance_near_type: 'Fungible Token Contract',
  token_ref_finance_near_mission: 'v1 (depreciated) - Mint REF token',
  refchef_near: 'refchef.near',
  simple_address: 'Simple Address',
  refchef_near_mission: 'Manage inter-account transactions',
  ref_dev_teller_near: 'ref-dev-teller.near',
  Locked_title: 'Locked? information box: when hovering to display:',
  Locked_paragraph_1:
    'Заблокований контракт означає, що немає ключів доступу, що дозволяють повторно розгорнути код контракту за допомогою.',
  Locked_paragraph_2:
    'У загальному випадку код можна повторно розгорнути за допомогою:',
  Locked_paragraph_3: '1. транзакції з дією deploy-code',
  Locked_paragraph_4:
    '2. сам контракт може реалізувати функцію виклику, який може викликати дію розгортання коду',
  Locked_paragraph_5:
    'Для повторного розгортання коду за deploy-code, транзакція повинна бути підписана ключем повного доступу. Якщо в контракті немає такого ключа, то немає можливості розгорнути код, якщо тільки в самому коді контракту немає спеціальної підтримки, і тому ми помічаємо такі контракти, як заблоковані.',
  Locked_paragraph_6:
    'Якщо на обліковому записі контракта зареєстровано, хоча б один ключ повного доступу, контракт не блокується.',
  audited_first_sentence:
    'Розумні контракти Ref Finance пройшли <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/audits" ' +
    '>аудит</a>.',
  admin_first_sentence:
    'Управління Ref Finance здійснюється<a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '> Ref Finance Sputnik DAO</a>. Існують <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" onclick=' +
    '>Guardians</a>, конкретні адреси NEAR, які можуть призупинити контракт. Тільки DAO може відновити контракт у будь-який час.',
  risks_of_using_ref_finance:
    '<label class="text-greenColor">Risks</label> Використання Ref. finance',
  third_party_wallet: 'Сторонній гаманець',
  third_party_wallet_1: 'Ref Finance інтегрує гаманці сторонніх розробників.',
  third_party_wallet_2:
    'Незважаючи на те, що ми вжили заходів для вибору авторитетних постачальників гаманців, ми не можемо гарантувати їхню безпеку чи продуктивність. Ви повинні ознайомитися з ризиками, пов’язаними з конкретним постачальником гаманця, а також зрозуміти його умови обслуговування, політику конфіденційності та методи безпеки.',
  third_party_wallet_3:
    'Користуйтеся цими послугами на власний ризик і будьте обережні.',
};
const uk_UA = {
  deposit: 'депозит',
  Deposit: 'Депозит',
  withdraw: 'зняття',
  Withdraw: 'Зняття',
  withdraw_token: 'зняти токен',
  swap: 'своп',
  Swap: 'Своп',
  exchange_tokens: 'обміняти токени',
  pool: 'Пул',
  Pool: 'Пул',
  pools: 'Пули',
  Pools: 'Пули',
  Quiz: 'Квіз',
  no_tokens_deposited: 'Депозит токенів відсутній',
  view_pools: 'Огляд пулів',
  view_pool: 'Огляд пулу',
  add_token: 'додати токен',
  Add_Token: 'Додати Токен',
  create_new_pool: 'створити новий пул',
  Create_New_Pool: ' Створити новий Пул',
  your_liquidity: 'Ваша ліквідність',
  Your_Liquidity: 'Ваша Ліквідність',
  farms: 'Фарм',
  Farms: 'Фарм',
  airdrop: 'Аірдроп',
  Airdrop: 'Аірдроп',
  docs: 'Документи',
  Docs: 'Документи',
  community: 'Спільнота',
  Community: 'Спільнота',
  more: 'Більше',
  More: 'Більше',
  account: 'Акаунт',
  Account: 'Акаунт',
  New_ui: 'Новий UI',
  Risks: 'Ризики',
  Forum: 'Форум',
  Discord: 'Discord',
  Telegram: 'Telegram',
  Twitter: 'Twitter',
  Medium: 'Medium',
  move_assets_to_from_ethereum: 'Перемістити активи до/з Ethereum',
  rainbow_bridge: 'Rainbow Bridge',
  deposit_to_swap: 'Депозит для свопу',
  connect_to_near: 'Підключитись до NEAR',
  slippage: 'Допуск прослизання',
  slippage_title: 'Налаштування транзакцій',
  minimum_received: 'Мінімум отриманий',
  swap_rate: 'Ставка свопу',
  swap_rate_pre: 'Ставка свопу',
  pool_fee: 'Комісія пулу',
  balance: 'Баланс',
  from: 'Від',
  to: 'До',
  submit: 'Прийняти',
  wrapnear: 'Обгорнути NEAR',
  wrapnear_tip_one: 'Обгортання NEAR дозволяє вам торгувати на REF',
  wrapnear_tip_two: 'Залиште 0.5 NEAR',
  wrapnear_tip_three: 'для плати за газ.',
  wrap_error_msg: 'Недостатній баланс',
  sign_out: 'Вийти',
  view_account: 'Огляд акаунта',
  deposit_to_swap_and_add_liquidity:
    'Здійсніть депозит, для обміну та додавання ліквідності',
  small_storage_fee_is_applied_of: 'Стягується невелика комісія за зберігання',
  liquidity: 'Ліквідність',
  tokens: 'Токени',
  fee: 'комісія',
  fees: 'комісії',
  view_detail: 'Переглянути деталі',
  pool_details: 'Деталі пулу',
  details: 'Деталі',
  detail: 'Деталі',
  liquidity_pools: 'Пули ліквідності',
  minimum_tokens_out: 'Мін. кількість отриманих',
  pair: 'Пара',
  tvl: 'TVL',
  search_pools: 'Пошук пулу',
  add_liquidity: 'Додати ліквідність',
  remove_liquidity: 'Забрати ліквідність',
  total_liquidity: 'Загальна ліквідність',
  coming_soon: 'Незабаром',
  accumulated_volume: 'Накопичений об&apos;єм',
  underlying_liquidity: 'Основна ліквідність',
  total_shares: 'Загальна частка',
  my_shares: 'Моя частка',
  token: 'Токен',
  enter_token_address: 'Введіть адресу токена',
  you_are_not_providing_liquidity_to_any_pools:
    'Ви не надаєте ліквідність жодним пулам',
  remove: 'Видалити',
  select: 'Вибрати',
  select_token: 'Вибрати токен',
  basis_points: 'Базові точки',
  total_fee: '% загальної комісії ',
  protocol_fee_is: 'Комісія протоколу складає ',
  start_in: 'Початок в',
  start_at: 'Початок в ',
  started_at: 'Розпочато о ',
  start_date: 'Дата початку',
  end_at: 'Закінчення о ',
  end_date: 'Кінцева дата',
  earn: 'Заробіток',
  ended: 'Закінчився',
  pending: 'ОЧІКУЄ',
  rewards_per_week: 'Винагороди за тиждень',
  apr: 'APR',
  total_staked: 'Загальна кількість в стейкінгу',
  unclaimed_rewards: 'Неотримані винагороди',
  claim: 'Отримати',
  claim_all: 'Отримати Все',
  stake: 'Стейкінг',
  unstake: 'Анстейкінг',
  your_rewards: 'Ваші винагороди',
  your_shares: 'Ваші частки',
  shares_owned: 'Частки у власності',
  stake_your_liquidity_provider_LP_tokens:
    'Здійсніть стейкінг своїх токенів постачальника ліквідності (LP)',
  go_to_wallet: 'Перейти до гаманця',
  recent_activity: 'Остання активність',
  view_all: 'Переглянути всі',
  cancel: 'Скасувати',
  claim_ref_token: 'Отримати Ref токен',
  has_no_available_claim: 'Активи для отримання відсутні',
  sorry: 'Вибачте',
  total_ref_token: 'Загальна кількість Ref токенів',
  locking_ref_token: 'Заблоковані Ref токени',
  unclaim_ref_token: 'Неотримані Ref токени',
  unlocked: 'Розблоковано',
  locking: 'Заблоковано',
  you_can_claim_from: 'Ви можете отримати з ',
  you_have_claimed_all_your_rewards: 'Ви отримали всі ваші винагороди',
  ends_in: 'Закінчується',
  days: 'днів',
  swap_successful_click_to_view: 'Успішний своп. Натисніть для перегляду',
  claim_successful_click_to_view: 'Успішно отримано. Натисніть для перегляду',
  claim_failed_click_to_view: 'Не вдалося отримати. Натисніть для перегляду',
  slippageCopyForMobile:
    'Прослизання означає різницю між тим, що ви очікуєте отримати, і тим, що ви насправді отримаєте, при виконанні інших ордерів першими',
  slippageCopy:
    'Прослизання означає різницю між тим, що ви очікуєте отримати, і тим, що ви насправді отримаєте, при виконанні інших ордерів першими',
  depositCopy:
    'Перенесення ваших вибраних токенів на <br> біржу для обміну або додавання до пулу ліквідности',
  nearDepositCopy:
    'Це спочатку обгортає, а потім переносить ваші Ⓝ на <br> біржу для обміну або додавання до пулу ліквідності',
  nearWithdrawCopy:
    'Це спочатку розгорне ваші Ⓝ, а потім зніме їх з <br> біржі та перекаже токени на ваш гаманець',
  withdrawCopy:
    'Це зніме ваші вибрані токени з <br> біржі та перенесе їх на ваш гаманець',
  registerToken: 'Це реєструє новий токен на <br> біржі який ще не торгується',
  whitelistTokenCopy: 'Додайте будь-який NEP-141 токен',
  addLiquidityPoolCopy:
    'Це створює новий пул ліквідності для двох вибраних токенів. Комісія — це відсоток, який пул бере з кожної транзакції',
  swapCopy:
    'Своп обмінює перший вибраний токен на другий  <br> вибраний токен. Будуть використані пули з найвищою доступною ліквідністю та найнижчою комісією обміну',
  addTokenCopy: 'Додайте будь-який NEP-141 токен',
  getLPTokenCopy: 'Натисніть тут, щоб перейти до відповідного пулу',
  airdropCopy:
    'Будь ласка отримайте свій аірдроп хоча б раз до дати закінчення терміну дії, інакше ваш баланс буде перераховано до казначейства',
  farmRewardsCopy:
    'Орієнтовна вартість на основі цін, а не фактичного виконання',
  totalValueLockedCopy: 'Загальна заблокована вартість',
  topPoolsCopy: 'Відображати лише найвищий пул TVL для кожної пари',
  you_do_not_have_enough: 'У вас недостатньо',
  must_provide_at_least_one_token_for: 'Необхідно надати більше 0 токенів для',
  is_not_exist: 'не існує',
  must_input_a_value_greater_than_zero: 'Необхідно ввести значення більше 0',
  amount_must_be_not_greater_your_balance:
    'Сума не повинна перевищувати ваш баланс',
  no_pool_available_to_make_a_swap_from: 'Відповідні пули для обміну відсутні',
  for_the_amount: 'на суму',
  no_pool_eng_for_chinese: ' ',
  is_not_a_valid_swap_amount: 'недійсна кількість свопу',
  not_nep_address:
    'Введена адреса не є адресою NEP-141, перевірте та введіть її ще раз. Якщо це інший контракт основної мережі, перейдіть до Rainbow Bridge для обробки через міст',
  not_correct_address:
    'Введена адреса неправильна, будь ласка перевірте її ще раз',
  popular_tokens: 'Популярні токени',
  asset_label: 'Актив',
  account_label: 'Акаунт',
  total_label: 'Всього',
  top_pools: 'Топ пул',
  pool_id: 'ID пулу',
  more_pools: 'Більше пулів',
  my_watchlist: 'Список спостережень',
  h24_volume: 'Обсяг за добу',
  volume: 'Volume',
  hide_low_tvl_pools: 'Приховати низький TVL',
  hide_low_tvl_pools_mobile: 'Приховати низький TVL',
  watchlist_title: 'Мій список спостережень вгорі',
  add_watchlist: 'Додати список спостережень',
  remove_watchlist: 'Видалити список спостережень',
  my_watchlist_copy: 'Тут з’являться обрані пули',
  search_token: 'Пошук токена',
  language: 'Мова',
  deposit_to_add_liquidity: 'Здійсніть депозит, щоб додати ліквідність',
  slip_warn: 'Будьте обережні, перевірте мінімум, який ви можете отримати',
  slip_invalid: 'Допус прослизання неправильний',
  input_to_search: 'Введіть для пошуку',
  click_search_bar_to_search: 'Натисніть на рядок пошуку, щоб знайти',
  reward_tokens: 'Токени винагород',
  detail_tip: 'Деталі',
  no_data: 'Дані відсутні',
  stable_swap: 'StableSwap',
  StableSwap: 'StableSwap',
  exchange_rate: 'Курс обміну',
  including_fees: '(включаючи комісії)',
  standard_gas: 'Стандартна комісія gas:',
  rst_token: 'Ваші RST токени',
  remove_tip: 'Комісія за видалення ліквідності частинами відсутня',
  share_liquidity: 'Частка ліквідності',
  remove_token_confirm: 'Ви видалите RUST токени',
  remove_as_one_token: 'Видалити як один токен',
  flexible_tip: 'Виберіть ціну за токен при видаленні',
  add_type_all: 'Додайте всі токени у збалансованій пропорції',
  add_type_max: 'Використовуйте максимальну кількість доступних токенів',
  select_balance: 'Виберіть баланс',
  selectBalanceCopy:
    'Виберіть [NEAR wallet], щоб напряму обмінювати токени, доступні у вашому гаманці NEAR.',
  near_wallet: 'NEAR гаманець',
  ref_account: 'REF акаунт',
  swap_tip:
    'Здійсніть своп із вашого REF акаунта, вибравши "REF акаунт" в налаштуваннях',
  attention: 'Увага',
  live: 'LIVE',
  ended_search: 'Завершено',
  my_farms: 'Ваш фарм',
  staked_only: 'Стейкінг',
  sort_by: 'Сортувати за',
  your_farms_rewards: 'Ваші фарм винагороди',
  your_farms: 'Ваш фарм',
  new: 'Нові',
  unstake_tip_t: 'У вас є неотримані винагороди...',
  unstake_tip_m:
    'Анстейкінг видаляє активи стейкінгу з усіх активних та очікуючих пулів фарму по одній парі.',
  unstake_tip_b: 'Ви дійсно бажаєте здійснити анстейкінг?',
  value_rewards: 'Отримані винагороди',
  stake_unstake_tip:
    'Стейкінг або анстейкінг автоматично отримає ваші винагороди.',
  over_tip:
    'Через обмеження комісії gas, одночасно можна вибрати не більше 4 позицій.',
  no_token_tip: 'Винагорода у токенах відсутня',
  getToken_tip:
    'Ви можете отримати свої винагороди або здійснити стейкінг своїх токенів LP прямо зараз!',
  in_farm: 'У фармі',
  swap_rate_including_fee: 'Ставка свопу (включаючи комісії)',
  by_share: 'За часткою',
  by_token: 'За токеном',
  your_positions_will_be_displayed_here:
    "Ваші позиції ліквідності з'являться тут.",
  price_impact: 'Вплив ціни',
  shares_left: 'Частки, які залишились',
  total_stable_coins: 'Всього стейблкоїнів',
  daily_volume: "Щоденний об'єм",
  liquidity_utilisation: 'Використання ліквідності',
  token_reserves: 'Cтатистика',
  roi_calculator: 'ROI калькулятор',
  lp_staked: 'LP стейкінгу',
  usd: 'USD',
  lp_token: 'LP токен',
  stake_for: 'Тривалість',
  day_1: '1 дн.',
  day_2: '7 дн.',
  day_3: '30 дн.',
  day_4: '90 дн.',
  day_5: '1 р.',
  cur_apr: 'ROI',
  reward_token: 'Токени винагород',
  get_lp_token: 'Отримати LP частки',
  calculate_roi: 'ROI калькулятор',
  rewards_claimed: 'Винагороди отримано',
  value_rewards_token: 'Вартість винагород',
  all: 'Всі',
  all_5: '4 токенів за раз',
  insufficient_shares: 'Часток недостатньо',
  shares_removed: 'Частки',
  shares_avaliable: 'Доступні частки',
  minimum_shares: 'Мін. частка',
  shares_tip: 'Доступні частки / Усього часток',
  stablecoin_only: 'Лише стейблкоїни',
  more_than: 'Будь ласка, введіть число, яке більше або дорівнює 0,01',
  less_than: 'Будь ласка, введіть число менше 20',
  no_valid: 'Будь ласка, введіть дійсний номер',
  lp_fee: 'LP комісія',
  protocol_fee: 'Комісія протокола',
  referral_fee: 'Реферальна комісія',
  protocol_fee_referral_fee: 'Комісія за протокол і реферальна плата',
  total_fee_create: 'Загальна комісія',
  xref_title: 'ЗАРОБІТЬ БІЛЬШЕ ЗА',
  xref_title1: 'Заробіть більше',
  xref_title2: 'здійснивши стейкінг REF у xREF',
  xref_introdution:
    'Здійснюючи стейкінг REF, ви маєте можливість заробляти комісії, що генеруються протоколом. Будь-який власник REF може отримати частку прибутків, отриманих Ref Finance.',
  staking_apr: 'APR стейкінгу',
  view_stats: 'Переглянути статистику',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed:
    'Будь ласка, введіть число, яке більше або дорівнює 0,000001',
  more_than_stable_seed: 'Будь ласка, введіть число, яке більше або дорівнює 1',
  are_you_sure: 'Ви впевнені',
  price_impact_is_about: 'Вплив на ціну становить приблизно',
  yes_swap: 'Так, здійснити своп',
  amount_must_be_greater_than_0: 'сума має бути більше 0',
  volume_ratio: "24 год. об'єм/ Коефіцієнт ліквідності",
  go_to_near_wallet: 'Перейти у NEAR Wallet',
  into_ref_account: 'у ваш акаунт REF.',
  deposit_into_ref_account: 'у ваш акаунт REF, щоб додати ліквідність',
  deposit_near_tip:
    'Щоб здійснити депозит, утримуйте щонайменше 1 NEAR для покриття комісії gas.',
  input_greater_than_available_shares: 'Введіть більше, ніж доступні частки',
  number_of_unique_stakers: 'Кількість унікальних стейкерів',
  total_ref_staked: 'Всього REF стейкінгу',
  total_xref_minted: 'Всього викарбувано xREF',
  protocol_projected_revenue: 'Прибуток, розділений між холдерами xREF',
  total_fee_Revenue_shared_with_xref_holders:
    'Загальний прибуток від комісійних, розділених між власниками xREF',
  provision_treasury: 'Казначейство забезпечення',
  protocol_projected_revenue_tip:
    'Це число відповідає сукупному загальному прибутку від торгових комісій власникам xREF. Він дорівнює 75% від загального доходу платформи. Воно може відрізнятися від фактичної суми зворотного викупу токена REF через коливання цін.',
  stake_ref_to_xref_for_earning_more:
    'Здійснюйте стейкінг  REF до xREF, щоб заробляти більше!',
  auto_router: 'Автомобільний роутер',
  auto_router_detail_sub_1: 'Цей маршрут оптимізує ваш своп',
  auto_router_detail_sub_2: 'враховуючи всі пули для однієї пари.',
  auto_router_detail_sub_3: 'Деталі:',
  introducing_parallel_swap: 'Представляємо Parallel Swap',
  at_least: 'Мінімум',
  xref_start_time: 'Початок о 01:00 18.02.2022 (UTC).',
  xref_to_receive: 'отримати xREF',
  ref_to_receive: 'отримати REF ',
  more_expensive_than_best_rate_en: 'дорожче за найкращу ставку',
  more_expensive_than_best_rate_zh_cn: '',
  optimal_path_found_by_our_solution:
    'Оптимальний шлях, знайдений нашим рішенням',
  check_to_stable_banner: 'Перевірити',
  rates_to_stable_banner: 'Ставки',
  make_sure_you_understand_what_you_do:
    'Переконайтеся, що ви розумієте, що ви робите',
  go_to_your_account_to: 'Перейдіть до свого акаунта для',
  deposit_l: 'Депозит',
  withdraw_l: 'Зняття',
  got_it: 'Зрозуміло',
  check_the_transaction_settings: 'Перевірте налаштування транзакції',
  filter_by: 'Відфільтрувати за',
  allOption: 'Всі',
  stablecoin: 'Стейблкоїн',
  near_ecosystem: 'Екосистема Near',
  bridged_tokens: 'Токени мосту',
  gaming: 'Ігри',
  nft: 'NFT',
  bridge: 'Міст до/з',
  from_ethereum: 'Ethereum',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
  no_result: 'На жаль, нам не вдалося знайти відповідний пул фарму',
  multiple: 'Декілька',
  default: 'За замовчуванням',
  vi_go_live: 'Доступна В&apos;єтнамська мова!',
  initialize_account_tip: 'Будь ласка, ініціалізуйте свій обліковий запис.',
  transaction_failed: 'Не вдалося виконати операцію',
  click_to_view: 'Клацніть, щоб переглянути',
  Type: 'Тип',
  create: 'створювати',
  support_ledger: 'Support Ledger',
  support_ledger_tip:
    'За своєю конструкцією Ledger не дозволяє обробляти великі транзакції (торгівлю через кілька пулів одночасно - Auto Router) через обмеження пам\'яті. У разі активації опції "Support Ledger" транзакції будуть обмежені до їхнайпростішої форми (оптимальні ціни необхідно обирати самостійно, Auto Router в цьому випадку не працює), і можуть бути підписані.',
  start: 'Початок',
  aprTip: 'Indicative value based on all rewards (pending and running)',
  half: 'Half',
  max: 'Max',
  ref_account_balance_tip:
    'Схоже сталася помилка, при додаванні/вилученні ліквідності в пулі',
  ref_account_tip_2: 'У вашому обліковому записі REF є токен(и)',
  sauce_note:
    'SAUCE  створений для пулів ліквідності активів з фіксованою вартістю (стейблкоінів), забезпечуючи найоптимальніший обмін.',
  ref_account_tip_top: 'ймовірно токен(и) знаходяться на вашому рахунку REF',
  click_here: 'Натисніть тут',
  to_recover_them: 'щоб їх відновити',
  ref_account_tip_3:
    'Щоб вивести токени з вашого облікового запису REF на свій гаманець NEAR, виберіть і витягніть',
  aurora_account_tip_4:
    'Щоб вивести токени з картографічного облікового запису на свій гаманець NEAR, виберіть і вийдіть',
  pool_fee_cross_swap: 'Плата за басейн/перехресний ланцюг',
  mapping_account: 'Картографічний обліковий запис',
  mapping_account_tip: 'У вас є маркери в картографічному обліковому записі',
  admin_sentence_1:
    'Ref Finance керується  <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a> і незабаром перейде до повністю децентралізованої DAO.',
  admin_sentence_2:
    'Для отримання додаткової інформації щодо контрактів та адрес, які безпосередньо керували або зараз керують справами Ref Finance, будь ласка, ознайомтесь з нашою  <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/contracts"' +
    '>Документацією.</a>.',
  cumulative_ref_buyback: 'Cumulative REF Buyback',
  yearly_revenue_booster: 'Yearly Revenue Booster',
  Confirm: 'Підтвердьте',
  Request_for_Quote: 'Запит пропозицій',
  mapping_account_explanation:
    'Адреса відображення - це "проксі-адреса", яка зіставляє обліковий запис користувача NEAR з відповідною ETH-адресою на Aurora.',
  buy: 'Купити',
  sell: 'Продати',
  buy_nsn: 'Купити USN',
  buy_nsn_tip: 'USN це нативна монета забезпечена економікою NEAR. ',
  usn_tip_one: 'USN дає вам змогу торгувати на REF. Переконайтесь, що  ',
  rate: 'Рейтинг',
  trading_fee: 'Комісія за торгівлю',
  usn_successful_click_to_view: 'Торгівля успішна. Натисніть, щоб переглянути',
  awesomeNear_verified_token: 'Токен перевірений AwesomeNear Verified Token',
  usn_fee_tip:
    'Ref стягує нульову комісію, користувачі сплачують лише комісію USN!',
  name: "Ім'я",
  diff: 'Дифs',
  best: 'Найкращий',
  total_bitcoins: 'Всього біткойнів',
  total_usd_value: 'Загальна вартість доларів США',
  bitcoin_value: 'Вартість біткойна',
  stable_coin_value: 'Вартість стейблкоіна',
  pool_fee_apr: 'Pool fee APY',
  ref_account_balance_tip_mobile: 'У вашому обліковому записі REF є токени.',
  click: 'Натисніть',
  to_recover: 'для відновлення.',
  rewards_week: 'Rewards/week',
  rewards: 'Rewards',
  POOL: 'Пули',
  STAKE: 'STAKE',
  lp_Token: 'LP Tokens',
  booster: 'Booster',
  slash_policy: 'Slash Policy',
  stake_period: 'Stake Period',
  append: 'Append',
  append_staking: 'Append Staking',
  unstake_time: 'Unstake Time',
  farm: 'Farm',
  reward_Token: 'Reward Token',
  status: 'Status',
  value_of_rewards: 'Value of Rewards',
  Rewards: 'Rewards',
  free: 'Free',
  connect_to_wallet: 'Підключити гаманець',
  anytime: 'Anytime',
  slash_policy_content:
    'Each seed has its own slash rate. And it will decreases linearly over time until be 0 at the unlock time of this CD Account.',
  not_expired: 'Not expired',
  accept_pay_slash_tip: 'I accept to pay slash',
  i_will_pay: 'I will pay',
  lp_token_slash: 'LP token exit fee.',
  booster_change_reason: 'Booster will change due to appending time',
  initial_booster: 'Initial Booster',
  append_timing: 'Append timing',
  cd_limit: 'more than 16 cd account limit',
  expired: 'Expired',
  more_than_seed: 'Input must be greater than or equal to ',
  reward_apr: 'Rewards APR',
  price_board: 'Price board',
  close: 'Close',
  borrow: 'Позичати',
  burrow_usn_tip: 'Ви можете позичити USN на Burrow.',
  unlock: 'Unlock',
  exit_fee: 'Exit fee',
  end_locking_period: 'End of locking period',
  slash_tip: 'Exit fee = Exit fee rate * End of locking period * Unlock amount',
  exit_fee_tip: 'Estimated amount you would pay to break your lock-up period',
  forbiddenTip: 'This farm does not support this duration option',
  swap_successful: 'Обмін успішно. ',
  trading_successfull: 'Торгівля успішна. ',
  total_near_amount: 'Загальна сума NEAR',
  recent_one_mounth_activity: 'Заходи за один місяць',

  applying_period: 'Applying Period',
  community_gauge_share: 'Community Gauge Share',
  qualified_pools: 'Qualified Pools',
  yes_continue: 'Yes, continue',
  vote_capital: 'Голосування',
  starting_time: 'Starting Time',
  days_ve: 'days',
  ending_time: 'Ending Time',
  please_choose_voting_duration: 'Будь ласка, виберіть тривалість голосування!',
  please_choose_proposal_voting_duration:
    'Please choose proposal voting duration',
  unlocking_tip:
    'Розблокування LP токенів автоматично відмінить ваш голос ( та відповідний бонус) в наступній пропозиції',
  stake_min_deposit: 'Введене значення має дорівнювати або бути більше ',
  unstakeTip: 'При стейкінгу та анстейкінгу винагороди автоматично збираються ',
  endedFarmsUnstakeTip:
    'Анстейкінг забере ваш стейк з усіх активних та очікуючих ферм тої самої пари ',
  comimg: 'протягом',
  appendTip: 'Не може бути раніше, ніж поточний час блокування',
  week: 'тиждень ',
  yours: 'Ваш',
  boost: 'підсилення',
  near: 'NEAR',
  eth: 'ETH',
  others: 'інші',
  stakeLove: 'стейкінг LOVE',
  unstakeLove: 'анстейкінг LOVE',
  get_love: 'отримати LOVE',
  near_value: 'Вартість NEAR',
  migrate: 'Мігрувати',
  go_to_new_farm: 'Перейти до Нової Ферми',
  voting_gauge_share_tip:
    'Частка REF, використаної для розподілу голосів, до загальної винагороди REF за цей період',
  swap_capital: 'Своп',
  farm_capital: 'Ферма',
  sauce_capital: 'SAUCE',
  dao_capital: 'DAO',
  available_to_stake: 'є можливість стейкінгу',
  claimed_Rewards: 'Отримані нагороди',
  all_5_v2: '5 токенів/час',
  all_4_v2: '4 токенів/час',
  search_farms: 'пошук ферм',
  ended_Farms: 'закінчені ферми',
  your_power: 'Ваша сила',
  farm_has_boost_tip:
    'Ваша сила = Ваші LP токени в стейкінгу * бустер (стейкаючи токени Love)',
  farm_no_boost_tip: 'Ваша сила = Your staked LP Tokens',
  search_by_token: 'Пошук за токеном',
  more_than_a_simple_pool: 'Більше ніж просто пул',
  create_pool: 'Створити пул',
  you_have: 'Ви маєте',
  lp_tokens: 'LP токени',
  bonus_tip:
    'Бонус за голосування розроблений для заохочення користувачів приймати участь в голосуванні. Ваш бонус залежить від кількості вашої частки',
  add_lp_tokens_tip:
    'LP токени потрібно стейкати в відповідній фермі. Спочатку добавте ліквідність в пул щоб отримати LP токени.',
  veTip: 'Токени veLP не підлягають передачі, veLPT дають вам право голосу ',
  loveTip:
    'LOVE розшифровується як Liquidity Of veToken. LOVE, як і будь-який токен NEP-141, можна передавати, обмінювати, закидати в пули, стейкати та фармити. LOVE представляє основну ліквідність ваших veTokens',

  lock_your_lp_tokens: 'Заблокуйте свої LP токени',
  unlock_your_defi_power: 'Розблокуйте силу DeFi',
  unlock_tip_mobile: 'Розблокування доступне зараз!',
  unlock_tip_top:
    'Термін вашого блокування минув, розблокування доступне зараз!',
  bonus_to_be_withdraw: 'бонус для зняття',
  keep_ve: 'тримати',
  months: 'міяці',
  month: 'місяць',
  lock_lp_tokens: 'Блокування токенів LP',
  get_lptoken: 'Отримання токенів LP',
  duration: 'Тривалість',
  ve_lock_tip: 'Не може бути раніше поточної дати розблокування',
  get: 'отримати',
  locking_ve: 'Блокування',
  in_addition_to_the: 'На додаток до',
  already_locked: 'Вже заблоковані',
  unlocking_is: 'Розблокування в',
  you_have_no_lp_share: 'У вас немає LP токенів',
  lock: 'Блок',
  unlock_lptoken: 'Розблокувати LP токени',
  locked: 'Заблоковані',
  available: 'в наявності',
  balance_lowercase: 'баланс',
  voting_power: 'Voting Power',
  lock_lp_tokens_first: 'Спочатку заблокуйте LP токени!',
  farm_booster: 'Farm Booster',
  go_to_farm: 'Перейти до фармінгу',
  in: 'в',
  more_lowercase: 'більше',
  avaliable_to_lock: 'Можна заблокувати',
  lock_lptoken: 'Заблокувати LP токени',
  lptoken_locker: 'LP Token Locker',
  filter: 'фільтер',
  bonus: 'Бонус',
  your_shares_ve: 'Ваша доля',
  voted: 'проголосовано',
  my_voting_power: 'My Voting Power',
  new_voting_ratio: 'New Voting Ratio',
  REF_allocation: 'аллокація REF',
  vote: 'Голосувати',
  preview: 'Перегляд',
  options: 'опції',
  ratio: 'Ratio',
  forum_discussion: 'обговорення на форумі',
  voted_veLPT: 'Проголосовано veLPT',
  your_contribution: 'Ваш вклад',
  your_assets: 'Ваші активи',
  connect_wallet: 'Підключіть гаманець',
  disconnect: 'Відключити',
  change: 'Змінити',
  wallets_below_supports_on_PC: 'Зазначені гаманці підтримуються на ПК',
  connect: 'Підключити',
  make_sure_device_plugged_in: 'Впевніться, що ваш пристрій підключено',
  then_enter_an_account_id_to_connect:
    'та введіть ваш ідентифікатор облікового запису для підключення',
  delete: 'Видалити',
  not_start: 'Не починати',
  no_veLPT: 'Ні veLPT',
  claim_bonus: 'отримати бонус',
  ended_ve: 'закінчилось',
  proposal_details: 'Деталі пропозиції',
  back: 'назад',
  pending_ve: 'в процесі',
  you_voted: 'Ви проголосували',
  add_bonus: 'додати бонус',
  you_vote: 'ви проголосували',
  Creator: 'Автор',
  turn_out: 'Явка',
  top_answer: 'найкраща відповідь',
  your_bonus: 'Ваш бонус',
  gauge_weight_vote: 'Gauge Weight Vote',
  governance: 'Управління',
  confirm: 'Підтвердити',
  voting_period: 'Період голосування',
  total: 'Загальна кількість',
  supply_voted: 'Кількість проголосваних',
  proposed: 'Запропоновано',
  farm_reward: 'Винагорода за фармінг',
  check_last_round: 'Перевірте останній раунд',
  last_round_voting_result: 'Результати останнього туру голосування',
  create_proposal: 'Створити пропозицію',
  type: 'Тип',
  add_option: 'додати опцію',
  lock_lp_terms:
    'Я розумію, що не зможу забрати свої токени LP протягом усього узгодженого періоду блокування',
  connect_to_near_wallet: 'Підключитись до NEAR Wallet',
  only_for_whitelist_address: 'Лише для адрес білого списку',
  v1Legacy: 'Спадок',
  v2New: 'Classic-Новий',
  v2_new_farms: 'Classic Нові Ферми',
  // migrate_now: 'Розпочніть міграцію зараз ! ',
  migrate_now: 'міграцію зараз ! ',
  v2_boost_tip:
    'Ферми Classic підтримуватимуть посилені ферми. На фермах Legacy закінчаться нагороди <span class="font-bold">1st. August, 2022.</span>',
  v2_boost_tip2: 'Ферми Classic підтримуватимуть посилені ферми.',
  v2_boost_no_tip:
    'На фермах Legacy закінчаться нагороди <span class="font-bold">1st. August, 2022.</span>',
  no_farm_need_to_migrate: 'Немає ферм для переносу',
  v1_farms: 'Classic Ферми',
  has_rewards_tip:
    'Вам все ще потрібно отримати винагороди в  <a rel="noopener noreferrer nofollow" class="text-greenColor text-base cursor-pointer underline hover:text-senderHot"  href="/farms" >Legacy Farms</a>. The rewards are not going anywhere, you can withdraw anytime.</div>',
  love: 'LOVE',
  Available_to_stake: 'Доступно для Стейкінгу',
  you_staked: 'Застейкано',
  has_rewards_tip_in_v2:
    'Legacy rewards to be withdraw in <a rel="noopener noreferrer nofollow" href="/farms" class="text-sm text-greenColor cursor-pointer underline ml-1 hover:text-senderHot"> Legacy Farms</a>.',
  how_to_get: 'Як отримати?',
  get_booster: 'отримати Booster',
  ended_farms: 'Закінчені ферми',
  boosted_farms: 'Boosted farms',
  your_farm: 'Ваші ферми',
  your_boosted_farms: 'Your boosted farms',
  search_farms_by_token: 'Шукати ферму за токеном',
  more_lock_ve_popup: 'більше ',
  more_lock_ve_popup2: 'більше',
  more_in: 'більше в',
  starts: 'Старт',
  left: 'Залишки',
  proposal_title: 'Назва пропозиції',
  share_forum_discussion_link_here:
    'Поділіться посиланням обговорення на форумі тут',
  required_field: "Обов'язкове поле",
  voting_power_and_extra_earnings: 'Сила в голосування та додатковий заробіток',
  view_on: 'Переглянути на',
  risks_capital: 'Ризики',
  boostFarmTip: 'Отримайте додаткові стимули за стейкінг LOVE',
  nonBoosted: 'Без стимулів',
  boosted: 'Зі стимулами',
  love_staked: 'Застейкані LOVE',
  your_apr: 'Ваш APR',
  range_apr: 'Діапазон чи стандартний APR',
  how_to_earn_more: 'Як поєднати винагороду?',
  no_pair_found: 'Пари не знайдено',
  found_stable_pool_in_sauce: 'Знайдено стабільний пул у SAUCE',
  lp_tokens_locking: ' LP токени ',
  you_currently_have: 'Ви зараз маєте ',
  scheduled_to_be_unlocked: 'заплановано для розблокування',
  locking_more_lp_tokens: 'Блокування більше токенів LP ',
  will_mean_these: 'буде означати ці ',
  lp_tokens_will_not_be_unlocked_until: ' Токени LP не буде розблоковано до',
  "I_understand_that_I_won't_be_able_to_remove_my":
    'Я розумію, що не зможу видалити свій ',
  until: 'поки',
  learn_more: 'Вивчайте більше',
  continue: 'Продовжити',
  then_enter_a_derivation_path_to_connect:
    'потім введіть шлях похідного підключення',
  account_identification_failed:
    'Помилка ідентифікації облікового запису! Будь ласка, надайте вручну',
  input_account_id: 'Введіть ідентифікатор облікового запису',
  we_found: 'Ми виявили',
  accounts_on_your_device: 'облікові записи на вашому пристрої',
  select_the_accounts_you_wish_to_connect:
    'Виберіть обліковий запис, який потрібно підключити',
  can_not_find_any_accounts:
    "Can't found any account associated with this Ledger. Please create a new NEAR account on",
  or_connect_another_ledger: 'or connect an another Ledger',
  add: 'додати',
  finish: 'Закінчити',
  add_custom_network: 'Додати спеціальну мережу',
  network_name: 'Назва мережі',
  rpc_name_taken_tip: 'Назва мережі вже зайнята',
  network_invalid: 'Мережа була недійсною',
  fobidden_testnet_rpc_tip:
    'Мережа сервера RPC (testnet) відрізняється від цієї мережі (mainnet)',
  no_support_testnet_rpc_tip: 'Testnet не підтримує додавання власного RPC',
  farm_only: 'Тільки ферма',
  value: 'значення',
  please_confirm_this_transaction_on_ledger:
    'Підтвердьте цю транзакцію в Ledger',
  trade_capital: 'ТОРГІВЛЯ',
  trade_capital_hump: 'Торгівля',
  pro: 'Про',
  limit: 'Ліміт',
  make_an_order: 'Створити ордер',
  your_active_orders_will_appear_here:
    'Ваші активні ордери відображатимуться тут',
  your_history_orders_will_appear_here:
    'Історія ваших ордерів відображатиметься тут',
  liquidity_capital: 'ЛІКВІДНІСТЬ',
  goes_to_limit_order: 'перейти до лімітного ордеру.',
  limit_order: 'Лімітний ордер',
  newText: 'Новий',
  farm_stop_tip: 'Ця ферма зупинена.',
  pool_stop_tip: 'Цей пул зупинений.',
  volume_24h: 'Обсяг (24 години)',
  add_to_watchlist: 'Додати до спостережень',
  remove_from_watchlist: 'Прибрати зі спостережень',
  create_order: 'Створити ордер',
  filled: 'Заповнений',
  canceled: 'Відмінено',
  partially_filled: 'Частково заповнений',
  pool_composition: 'Склад пулу',
  amount: 'Кількість',
  h24_volume_bracket: 'Обсяг (24 години)',
  fee_24h: 'Комісія (24 години)',
  cross_chain_swap: 'Міжмережевий обмін',
  orders: 'замовлення',
  your_orders: 'Ваші замовлення',
  active: 'Активний',
  history: 'Історія',
  you_sell: 'Ваші продажі',
  you_buy: 'Ваші покупки',
  fee_tiers: 'Рівні комісій',
  order_rates: 'Ставка ордеру',
  created: 'Створено',
  executed: 'Виконаний',
  actions: 'Дії',
  claimed_upper: 'Отримано',
  open_my_order: 'Відкрити',
  initial_order: 'Початковий ордер',
  this_order_has_been_partially_filled: 'Цей ордер заповнений частково',
  filled_via_swap: 'Заповнено за рахунок обміну',
  state: 'Стан',
  partially: 'Частково',
  select_tokens: 'Вибрати токени',
  fee_Tiers: 'Рівні Комісій',
  select_s: 'вибрати',
  no_pool: 'Пул відсутній',
  input_amount: 'Зазначте кількість',
  update_range: 'Оновити діапазон',
  not_enough_balance: 'Не достатній баланс',
  set_price_range: 'Встановити ціновий діапазон',
  current_price: 'Ціна зараз',
  pool_creation: 'Створення пулу',
  pool_creation_tip:
    'Для вибраних токенів пул відсутній. Для створення пулу, ви повинні встановити ставку пулу, вказавши необхідні значення.',
  starting_price: 'Початкова ціна',
  create_a_pool: 'Створити пул',
  add_single_price_tip:
    'Ваша позиція не отримуватиме винагороду та не використовуватиметься в торгівлі доки ринкова ціна не зайде у визначений діапазон.',
  invalid_range_tip:
    'Задано не вірний діапазон. Мінімальна ціна має бути нижчою за максимальну.',
  market_price_outside_tip:
    'Ринкова ціна знаходиться за межами вашого цінового діапазону.',
  market_price_outside_single_only_tip:
    'Ринкова ціна знаходиться за межами вашого цінового діапазону. Внесіть тільки один актив.',
  min_price: 'Мінімальна ціна',
  max_price: 'Максимальна ціна',
  min: 'Мін',
  out_of_range: 'Поза межами',
  in_range: 'В межах',
  unclaimed_fees: 'Неотримані комісії',
  your_position_will_be: 'Ваша позиція буде',
  at_this_price: 'за цією ціною',
  price_range: 'Ціновий діапазон',
  positions: 'Позиції',
  add_new_position: 'Додати нову позицію',
  range: 'Діапазон',
  unclaimed_fee: 'Неотримана комісія',
  near_validation_error: 'Must have 0.5N or more left in wallet for gas fee.',
  no_positons_in_this_pool_yet: 'No positons in this pool yet',
  no_claimed_rewards_yet: 'No claimed rewards yet',
  out_of: 'out of',
  value_must_be_less_than_or_equal_to: 'Value must be less than or equal to',
  maket_price_outside_tip:
    'Ринкова ціна знаходиться за межами вашого цінового діапазону.',
  maket_price_outside_single_only_tip:
    'Ринкова ціна знаходиться за межами вашого цінового діапазону. Внесіть тільки один актив.',
  login_risk_tip:
    'By checking this box and moving forward, you confirm that you fully understand the <a class="text-greenColor text-sm font-bold cursor-pointer hover:underline" href="/risks" ' +
    ')">risks</a> of using Ref Finance.',
  no_pool_tip: 'Oops! The pool does not exist.',
  total_ref_week: 'Total REF/week',
  ref_week_you_will_get: 'REF/week you will get',
  stable_pool: 'Stable Pool',
  market_rate: 'Market Rate',
  limit_price_tip:
    'To improve deal the efficiency, your price should be in one slot nearby automatically',
  no_token_found: 'No token found',
  search_name_or_address: 'Search name or paste address...',
  token_address_invalid: 'The token address was invalid',
  price_impact_warning: 'Price Impact Warning',
  confirm_order: 'Confirm Order',
  you_Sell: 'You Sell',
  to_Buy: 'to Buy',
  at_Price: 'at Price',
  insufficient_balance: 'Insufficient Balance',
  MORE: 'MORE',
  limitTip: 'Your price is automatically set to the closest price slot',
  v2PoolTip: 'You can have up to 16 positions in DCL pools',
  orderTip: 'You can have up to 16 active/history limit orders',
  v2_pools: 'DCL Pools',
  minimum_received_dot: 'Мінімум отриманий',
  v2_your_pool_introduction: 'Your discretized concentrated liquidity',
  v1_your_pool_introduction: 'Your liquidity in classic pools',
  estimate_value: 'Estimate Value',
  your_investment: 'Your Investment',
  your_investment_tip:
    'USD value of your investment on Ref:Classic pools + DCL pools (including staked in farms) + xREF',
  classic_pools: 'Classic Pools',
  xref_staking: 'xREF Staking',
  token_balances: 'Token Balances',
  unclaimed_earnings: 'Unclaimed Earnings',
  unclaimed_earnings_tip:
    'USD value of unclaimed fees from DCL pools, and unclaimed farm rewards.',
  unclaimed_pool_fees: 'Unclaimed Pool Fees',
  unclaimed_farm_rewards: 'Unclaimed Farm Rewards',
  active_orders: 'Active Orders',
  yield_farming: 'Yield Farming',
  execute_status: 'Execute Status',
  your_liquidity_usd_value: 'Your Liquidity (USD value)',
  usage: 'Usage',
  holding: 'Holding',
  your_lp_tokens_and_shares: 'Your LP Tokens(Shares)',
  usd_value_staked: 'USD Value Staked',
  instant_swap: 'Instant Swap',
  swapped: 'Swapped',
  created_s: 'created',
  order_progress: 'Order Progress',
  dcl_pool: 'DCL Pool',
  classic: 'Classic',
  classic_farms: 'Classic Farms',
  unstaked: 'Unstaked',
  staked_in_farm: 'Staked in farm',
  farm_reward_range: 'Farm Reward Range',
  your_positions: 'Your Positions',
  your_price_range: 'Your Price Range',
  locked_in: 'locked in',
  executing: 'Executing',
  from_2: 'from',
  to_2: 'to',
  your_orders_2: 'Your Orders',
  in_farm_2: 'in farm',
  your_liquidity_2: 'Your Liquidity',
  legacy_tip: 'Found unstaked LP tokens or rewards in',
  legacy_farms: 'Legacy Farms',
  all_farms: 'All Farms',
  your_farms_2: 'Your Farms',
  dcl_farms: 'DCL Farms',
  farm_type: 'Farm type',
  farm_2: 'Farm',
  to_claim: 'To Claim',
  liquidity_staked: 'Liquidity staked',
  dcl_pool_detail: 'DCL Pool Detail',
  reward_range: 'Reward Range',
  reward_range_tip: 'Farm reward within this range',
  ending_soon: 'Ending soon',
  farm_ended_tip: 'This farm has ended.',
  farm_will_ended_tip: 'This farm will end soon.',
  new_farm: 'New Farm',
  is_coming: 'is coming',
  est_apr: 'Est. APR',
  state_2: 'State',
  farming: 'Farming',
  unavailable: 'Unavailable',
  liquidity_detail: 'Liquidity Detail',
  hide: 'Hide',
  show: 'Show',
  unavailable_positions: 'unavailable positions',
  your_price_range_tip: 'Your price range is out of reward range',
  position_has_staked_tip: 'This position has been staked in another farm',
  minimum_tip: 'The minimum staking amount is',
  your_liquidity_3: 'your liquidity',
  position_has_staked_pre: 'This position has been staked in',
  another_farm: 'another farm',
  add_position: 'Add Position',
  full_range: 'Full Range',
  your: 'Your',
  max_apr: 'Max.APR',
  faming_positions: 'Farming Positions',
  you_can_earn_tip: 'You can earn rewards by farming, est. APR is',
  you_can_earn_current_tip:
    'Your current staked farm ended, and new farm is coming, est. APR is',
  go_new_farm: 'Go New Farm',
  go_farm: 'Go Farm',
  earn_rewards: 'You can earn rewards',
  est_apr_is: 'est. APR is',
  new_farm_2: 'new farm',
  you_can_earn_current_pre_tip: 'Your current staked farm ended, and',
  in_ended_farm: 'in ended farm',
  staked: 'Staked',
  in_farm_3: 'in farm',
  estimation: 'Estimation Value',
  no_dcl_position_tip:
    "You don't have any DCL Liquidity position for now, click 'AddPosition' to start farming.",
  price_on_slot_tip: 'The price should be in one slot nearby.',
  position_will_appear_here: 'Your liquidity positions will appear here.',
  by_farming: 'by farming',
  this_staked_tip: 'This NFT has been staked',
  dcl_liquidity: 'DCL Liquidity',
  classic_liquidity: 'Classic Liquidity',
  will_appear_here_tip: 'Your liquidity positions will appear here.',
  dcl_will_appear_here_tip: 'Your DCL liquidity positions will appear here.',
  classic_will_appear_here_tip:
    'Your Classic liquidity positions will appear here.',
  yield_farming_appear_here_tip: 'Your yield farming will appear here.',
  active_order_appear_here_tip: 'Your active order(s) will appear here.',
  account_appear_here_tip: 'Your wallet/account assets will appear here.',
  farm_state: 'Farm State',
  farm_detail: 'Farm Detail',
  unstaked_2: 'Unstaked',
  unstaked_positions: ' Unstaked Positions',
  price_range_overlap_ratio: ' Price Range Overlap Ratio',
  swapped_history: 'swap history',
  overview: 'Overview',
  swapped_history_order: 'Swapped',
  positions_2: 'Positions',
  'deposit_near_tip_0.5': 'To deposit, keep at least 0.5 NEAR to cover gas fee',
  trade: 'Trade',
  earn_2: 'Earn',
  portfolio: 'Portfolio',
  more_2: 'More',
  liquidity_pools_2: 'Liquidity Pools',
  business_inquiries: 'Business Inquiries',
  orderbook_mobile: 'Orderbook',
  real_time_executed_orders: 'real-time executed orders',
  real_time_executed_orders_tip:
    'When placing a sell order above market price or a buy order below market price using limit order function, Ref will attempt to execute a swap-with-stop-point action first, and if there is any remaining amount, a limit order will be created to fill the rest.<br> Real-time executed orders refer to orders placed using the limit order function but executed through swapping. In the "History" section, you can view real-time executed orders between the earliest displayed limit order in History up to the present time.',
  ledger_guide_tip_1:
    'To successfully use your Ledger wallet for transactions, the ',

  ledger_guide_tip_2: 'feature has been automatically activated.',
  ledger_guide_tip_3: 'Please be aware',
  support_ledger_tip_new: '"Support Ledger"',

  ledger_guide_tip_4:
    'that due to Ledger wallet constraints, the current rate provided by the swap function',
  ledger_guide_tip_5: 'may not be the best price.',
  view_dcl_pool: 'View DCL Pool',
  your_trade_route: 'Your Trade Route',
  markets: 'Markets',
  exchanges: 'Exchanges',
  output_est: 'Output (est.)',
  go_to_orderbook: 'Go to Orderbook',
  new_swap_order_tip:
    'The price is from the Ref AMM offer and for reference only. There is no guarente that your limit order will be immediately filled. ',
  instants_swap: 'Instant Swap',
  swappped: 'Swapped',
  claimed: 'Claimed',
  executing_capital: 'Executing',
  record: 'Record',
  trade_route: 'Trade Route',
  settings: 'Settings',
  cross_chain_options: 'Cross-chain Options',
  no_trade_routes: 'No Trade Routes',
  steps_in_the_route: 'Steps in the Route',
  swap_chart_no_data: 'Not enough data for the chart right now.',
  last_updated: 'Last Updated',
  steps_in_the_route_zh: '',
  has_tri_pool_tip:
    ' in Ref. Enable Trisolaris in Cross-chain Options to find more.',

  Overview: 'Overview',
  RefFinance: 'Ref.finance',
  Orderly: 'Orderly',
  Burrow: 'Burrow',
  Supplied: 'Supplied',
  Borrowed: 'Borrowed',
  NetAPY: 'Net APY',
  UnclaimedRewards: 'Unclaimed Rewards',
  YouSupplied: 'You Supplied',
  SupplyMarket: 'Supply Market',
  YouBorrowed: 'You Borrowed',
  BorrowMarket: 'Borrow Market',
  NetAPYTip:
    'Net APY of all supply and borrow positions, including base APYs and incentives',
  Market: 'Market',
  TotalSupplied: 'Total Supplied',
  TotalBorrowed: 'Total Borrowed',
  AvailableLiquidities: 'Available Liquidity',
  DailyRewards: 'Daily Rewards',
  SupplyAPY: 'Supply APY',
  BorrowAPY: 'Borrow APY',
  Collateral: 'Collateral',
  Adjust: 'Adjust',
  Supply: 'Supply',
  Repay: 'Repay',
  Borrow: 'Borrow',
  HealthFactor: 'Health Factor',
  CollateralFactor: 'Collateral Factor',
  AdjustCollateral: 'Adjust Collateral',
  UseAsCollateral: 'Use as Collateral',
  RemainingCollateral: 'Remaining Collateral',
  RemainingBorrow: 'Remaining Borrow',
  NoSupplyTip: 'Your supplied assets will apear here.',
  NoBorrowTip: 'Your borrowed assets will apear here.',
  APY: 'APY',
  CF: 'C.F.',
  burrowErrorTip:
    'The current balance is below the minimum token decimals, so that it cannot be processed by the contract.',
  healthErrorTip:
    "Your health factor will be dangerously low and you're at risk of liquidation.",
  Summary: 'Summary',
  Welcome: 'Welcome!',
  ConnectView: 'Connect your wallet to view',
  TotalSuppliedMarket: 'Total Supplied',

  NetWorth: 'Net Worth',
  Claimable: 'Claimable',
  TotalDebts: 'Total Debt',
  WalletAssets: 'Wallet Assets',
  TotalInvested: 'Total Invested',
  TotalAssets: 'Total Assets',
  TokenAllocation: 'Token Allocation',
  bridge_pure: 'Міст',
  netWorthTip:
    'Total value of investments in Ref (including claimable rewards) + Total value of Orderly assets + Total value of Burrow assets + Total assets in wallet',
};
export default Object.assign(uk_UA, uk_UA_in_risks_page);
