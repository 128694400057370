// risk
const es_in_risks_page = {
  risks: 'Riesgos',
  of_using_ref_finance: 'al utilizar Ref. finance',
  introduction:
    'Proporcionar liquidez y/o negociar en Ref Finance no está exento de riesgos. Antes de interactuar con el protocolo, investigue y comprenda los riesgos involucrados.',
  general_risks_quiz: 'Quiz para determinar riesgos',
  amm_core_design: 'Diseño AMM Core ',
  audits: 'Auditores',
  audits_paragraph_2:
    'Las auditorías de seguridad no eliminan los riesgos completamente. Se recomienda no hacer uso de los ahorros o activos, en Ref Finance, que no pueda permitirse perder, para porveerse de liquidez.',
  admin_keys: 'Admin llaves',
  admin_keys_paragraph_2:
    'El actual DAO está constituido por 26 miembros. Ref Finance irá evolucionando hasta ser una DAO totalmente descentralizada.',
  admin_keys_paragraph_3:
    'A continuación encontrará la lista de contratos y direcciones que han gestionando directamente o actualmente se está haciendo para los asuntos de Ref Finance.',
  address: 'Dirección',
  type: 'Tipo',
  mission: 'Misión',
  Locked: 'Bloqueado',
  exchange_contract: 'Contrato de intercambio',
  table_body_tr_1:
    'Administrar las funciones de Creador de Mercado Automatizado; Intercambiar y proporcionar liquidez',
  farming_contract: 'Contratos de Farming',
  table_body_tr_2: 'Gestionar incentivos de liquidez',
  staking_contract: 'Contratos de Staking',
  table_body_tr_3:
    'Mintear y burn xREF, y distribuir recompensas basadas en el tiempo',
  sputnik_dao_contract: 'Contrato Sputnik DAO',
  table_body_tr_4:
    'Asegure el éxito en Ref tomando decisiones estratégicas (incl. smart contract amendments)',
  table_body_tr_5:
    'Administrar y asignar fondos a contribuyentes comunitarios específicos',
  table_body_tr_6: 'Ejecutar la estrategia y la hoja de ruta',
  vesting_contract: 'Contrato de adjudicación',
  table_body_tr_7:
    'Administrar los contratos de concesión de REF de los miembros de Dev DAO',
  airdrop_contract: 'Contrato Airdrop',
  table_body_tr_8: 'Gestionar el primer REF airdrop',
  near_address: 'Dirección NEAR',
  table_body_tr_9: 'Manejo de pagos únicos de recompensas por errores (bugs)',
  fungible_token_contract: 'Contrato de Token Fungible',
  table_body_tr_10: 'Mint REF token',
  rug_pull: 'Rug pull',
  rug_pull_paragraph_1:
    'Si el equipo detrás de un token, ya sea que esté en el whitelist o no, decide abandonar su proyecto y toma el dinero de los inversionistas, el token del proyecto probablemente valdrá $0.',
  rug_pull_paragraph_2:
    'Si el token está en el whitelist  de Ref Finance, eso no significa que el proyecto tendrá éxito. Usted es responsable de hacer su propia diligencia debida del proyecto y debe comprender que las criptomonedas son inversiones especulativas de muy alto riesgo.',
  rug_pull_paragraph_3:
    'Debe ser consciente y estar preparado para perder potencialmente parte o la totalidad del dinero invertido.',
  divergence_loss: 'Divergence Loss',
  divergence_loss_paragraph_1:
    'Si proporciona liquidez, tenga en cuenta que puede ganar más dinero al no proporcionar liquidez.',
  divergence_loss_paragraph_2:
    'La denominada Divergence Loss a menudo se le llama“impermanent loss”. El adjetivo (impermanent) puede suponer o crear la sensación de que las pérdidas en el mercado son solo temporales, lo que significa que se garantiza que las pérdidas se revertirán, lo cual no es cierto.',
  divergence_loss_paragraph_3: 'Aprenda más acerca de la  Divergence Loss',
  staking_risks: 'Riesgos en el Staking',
  staking_risks_paragraph_1:
    'Al hacer Staking, se utilizan múltiples productos de contratos inteligentes, cada uno de los cuales tiene sus propios riesgos.',
  permanent_loss_of_a_peg: 'Pérdida permanente de PEG (PER)',
  permanent_loss_of_a_peg_paragraph_1:
    'Si uno de los stablecoins en el pool cae significativamente por debajo del PEG (PER) de 1.0 y nunca regresa al PEG (PER), siignificará que los proveedores de liquidez mantienen casi toda su liquidez en esa moneda.',
  systemic_issues: 'Problemas sistémicos',
  systemic_issues_paragraph_1:
    'En general, los legos del ecosistema de las finanzas descentralizadas (DeFi) están sumamente conectados, lo que significa que una falla de su componente puede desencadenar una sucesión de fallas.',
  systemic_issues_paragraph_2:
    'Un riesgo sistemático significa que se puede perder dinero, incluso si la falla no afecta directamente a su inversión/exhibición.',
  systemic_issues_paragraph_3:
    'Los siguientes riesgos pueden tener un impacto en los fondos de liquidez:',
  systemic_issues_paragraph_4:
    'Problemas de contratos inteligentes con protocolos de préstamo',
  systemic_issues_paragraph_5:
    'Problemas de contratos inteligentes con protocolos de participación',
  systemic_issues_paragraph_6:
    'Problemas sistémicos con los stablecoins en los pools',
  systemic_issues_paragraph_7:
    'Problemas sistémicos con ERC20-native tokens en los pools',
  crypto_trading_addiction: 'Adicción a la compra- venta de Cryptomonedas',
  crypto_trading_addiction_paragraph_1:
    'El comercio de criptomonedas puede ser muy adictivo y, según varias fuentes, puede ser una forma de adicción al juego que puede destruir vidas.',
  crypto_trading_addiction_paragraph_2:
    'A continuación encontrará una colección de historias relacionadas con ese asunto.',
  crypto_trading_addiction_paragraph_3:
    "'Negociar es apostar, no hay duda al respecto,'",
  crypto_trading_addiction_paragraph_4:
    "'Perdí medio millón de libras comerciando con Bitcoin'",
  crypto_trading_addiction_paragraph_5:
    "'Hablamos con un terapeuta que trata la adicción al comercio de criptomonedas'",
  crypto_trading_addiction_paragraph_6:
    "'Perdí millones a través de la adicción al comercio de criptomonedas'",
  no_in_progress: 'No (En plena marcha)',
  yes: 'Sí',
  no: 'No',
  NA: 'N/A',
  ref_finance_near: 'ref-finance.near',
  ref_finance_near_mission:
    'v1 (depreciated)- Manejo de las funciones de la Automated Market Maker functions; Swap (Intercambio) y Provide Liquidity (Proveer liquidez)',
  token_ref_finance_near: 'token.ref-finance.near',
  token_ref_finance_near_type: 'Contrato del Token Fungible',
  token_ref_finance_near_mission: 'v1 (depreciated) - Mint REF token',
  refchef_near: 'refchef.near',
  simple_address: 'Simple Address',
  refchef_near_mission:
    'Manejo de la transacciones entre cuentas (inter-account transactions)',
  ref_dev_teller_near: 'ref-dev-teller.near',
  Locked_title:
    'Bloqueado? cuadro de información: pasar el cursor para mostrar:',
  Locked_paragraph_1:
    'Contrato bloqueado significa que no hay claves de acceso que permitan volver a implementar el código del contrato',
  Locked_paragraph_2: 'Por lo general, el código puede ser reimplementado por:',
  Locked_paragraph_3:
    '1. una transacción con una acción de implementación de código',
  Locked_paragraph_4:
    '2. el contrato en sí puede implementar una llamada de la función que activará la acción de código de implementación',
  Locked_paragraph_5:
    'Para volver a implementar el código con una transacción, la transacción debe firmarse con una clave de acceso completo.  Si no hay dicha clave en el contrato, no hay forma de volver a implementar el código a menos que haya un soporte dedicado en el propio código del contrato y, por lo tanto, marcamos dichos contratos como bloqueados.',
  Locked_paragraph_6:
    'Si hay al menos una clave de acceso completo registrada en la cuenta del contrato, el contrato no está bloqueado.',
  audited_first_sentence:
    'Los contratos inteligentes de Ref Finance son etiquetados como <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/audits"' +
    '>audited</a>.',

  admin_first_sentence:
    'Ref Finance es gestionado por la etiqueta  <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a>. There are <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://gov.ref.finance/t/introducing-the-guardians/253"' +
    '>Guardians</a>, son las direcciones específicas de NEAR specific NEAR, que son capaces de pausar el contrato. Solo el DAO puede reanudar el contrato, en cualquier momento.',
  risks_of_using_ref_finance:
    '<label class="text-greenColor">Riesgos</label> de Usar Ref.finance',
  admin_sentence_1:
    'Ref Finance es gestionado por la etiqueta  <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a> y hará la transición a un DAO completamente descentralizado.',
  admin_sentence_2:
    'Para obtener más información relacionada con los contratos y las direcciones que han gestionado directamente o gestionan actualmente los asuntos de Ref Finance, consulte nuestro <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/contracts"' +
    '>Documentation</a>.',
  third_party_wallet: 'Monedero de terceros',
  third_party_wallet_1: 'Ref Finance integra carteras de terceros.',
  third_party_wallet_2:
    'Si bien hemos tomado medidas para seleccionar proveedores de billeteras de buena reputación, no podemos garantizar su seguridad o rendimiento. Debe familiarizarse con los riesgos asociados con el proveedor de billetera específico que tenemos y comprender sus términos de servicio, política de privacidad y prácticas de seguridad.',
  third_party_wallet_3:
    'Utilice estos servicios bajo su propio riesgo y tenga cuidado.',
};
// Main-page
const es = {
  deposit: 'Depósito',
  Deposit: 'Depósito',
  withdraw: 'Retiro',
  Withdraw: 'Retiro',
  withdraw_token: 'Retido de Token',
  swap: 'Swap',
  Swap: 'Intercambio',
  exchange_tokens: ' Intercambio de Tokens',
  pool: 'Pool',
  Pool: 'Pool',
  pools: 'Pools',
  Pools: 'Pools',
  Quiz: 'Prueba',
  no_tokens_deposited: 'No hay Tokens Depositados',
  view_pools: 'Ver Pools',
  view_pool: 'Ver Pool',
  add_token: 'Añadir Token',
  Add_Token: 'Añadir Token',
  create_new_pool: 'Crear un nuevo pool',
  Create_New_Pool: 'Crear un nuevo Pool',
  your_liquidity: 'Tu Liquidez',
  Your_Liquidity: 'Tu Liquidez',
  farms: 'Farms',
  Farms: 'Farms',
  airdrop: 'Airdrop',
  Airdrop: 'Airdrop',
  docs: 'Docs',
  Docs: 'Docs',
  community: 'Comunidad',
  Community: 'Comunidad',
  more: 'Más',
  More: 'Más',
  account: 'Cuenta',
  Account: 'Cuenta ',
  New_ui: 'Nueva UI',
  Risks: 'Riesgos',
  Forum: 'Foro',
  Discord: 'Discord',
  Telegram: 'Telegram',
  Twitter: 'Twitter',
  Medium: 'Medium',
  move_assets_to_from_ethereum: 'Mover activos hacia/desde Ethereum',
  rainbow_bridge: 'Rainbow Bridge',
  deposit_to_swap: 'Depositar para Intercambiar (Swap)',
  connect_to_near: 'Conectarse a NEAR',
  slippage: 'Tolerancia al deslizamiento',
  slippage_title: 'Configuración de la Transacción',
  minimum_received: 'Mínimo recibido',
  swap_rate: 'Tasa de intercambio',
  swap_rate_pre: 'Tasa de intercambio',
  pool_fee: 'Pago por Pool',
  balance: 'Balance',
  from: 'Desde',
  to: 'para',
  submit: 'Enviar',
  wrapnear: 'Wrap NEAR',
  wrapnear_tip_one:
    'Wrapping NEAR permite hacer los intercambios en REF. Asegúrate de hacerlo ',
  wrapnear_tip_two: 'deja 0.5 NEAR',
  wrapnear_tip_three: ' para el pago del gas .',
  wrap_error_msg: 'No dispones de lo suficiente Not enough balance',
  sign_out: 'Desconectar',
  view_account: 'Ver tu cuenta',
  deposit_to_swap_and_add_liquidity:
    'Deposita para intercambiar y añadir liquidez',
  small_storage_fee_is_applied_of:
    'Se aplica una pequeña tarifa por el almacenamiento ',
  liquidity: 'Liquidez',
  tokens: 'Tokens',
  fee: 'Tarifa',
  fees: 'Tarifas',
  view_detail: 'Ver el detalle',
  pool_details: 'Detalles del Pool',
  details: 'Detalles',
  detail: 'Detalle',
  liquidity_pools: 'Pools de Liquidez',
  minimum_tokens_out: 'Mínimo recibido',
  pair: 'Par',
  tvl: 'TVL',
  search_pools: 'Search Pool',
  add_liquidity: 'Añadir Liquidez',
  remove_liquidity: 'Remover Liquidez',
  total_liquidity: 'Total de  liquidez',
  coming_soon: 'Próximamente',
  accumulated_volume: 'Volumen Acumulado',
  underlying_liquidity: 'Liquidez subyacente',
  total_shares: 'Acciones Totales',
  my_shares: 'Acciones',
  token: 'Token',
  enter_token_address: 'Ingrese la dirección del token',
  you_are_not_providing_liquidity_to_any_pools:
    'No estás proporcionando liquidez a ningún Pool',
  remove: 'Retirar',
  select: 'Seleccionar',
  select_token: 'Seleccionar el Token',
  basis_points: 'Basis points',
  total_fee: '% total de la tarifa ',
  protocol_fee_is: 'la tarifa del protocolo es ',
  start_in: 'Empezar en',
  start_at: 'Empezó a las',
  started_at: 'Empezó a las',
  start_date: 'Fecha de inicio',
  end_at: 'Termina en',
  end_date: 'fecha final',
  earn: 'Ganancia',
  ended: 'Terminada',
  pending: 'Pendiente',
  rewards_per_week: 'Recompensas por semana',
  apr: 'APR',
  total_staked: 'Total staked',
  unclaimed_rewards: 'Recompensas no cobradas',
  claim: 'Cobradas',
  claim_all: 'Cobrado todo',
  stake: 'Stake',
  unstake: 'Unstake',
  your_rewards: 'Tus recompensas',
  your_shares: 'Acciones',
  shares_owned: 'Acciones propias',
  stake_your_liquidity_provider_LP_tokens:
    'Stake your Liquidity Provider (LP) tokens',
  go_to_wallet: 'Ve a tu Wallet',
  recent_activity: 'Actividad reciente',
  view_all: 'Ver todo',
  cancel: 'Cancelar',
  claim_ref_token: 'Recamar el Ref Token',
  has_no_available_claim: 'no hay nada que reclamar',
  sorry: 'Lo sentimos',
  total_ref_token: 'Total Ref Token',
  locking_ref_token: 'Ref Token Bloqueados',
  unclaim_ref_token: 'Ref Token no reclamados',
  unlocked: 'Unlocked',
  locking: 'Bloqueados',
  you_can_claim_from: 'Puedes reclamar de',
  you_have_claimed_all_your_rewards: 'Has reclamado todas tus recompensas',
  ends_in: 'Termina en',
  days: 'dias',
  swap_successful_click_to_view: 'Swap realizada exitosamente. Click para ver',
  claim_successful_click_to_view:
    'Reclamado (Claim) realizado con éxito. Click para ver',
  claim_failed_click_to_view:
    'Hubo una falla al realizar el reclamo (Claim) . Click para ver',
  slippageCopyForMobile:
    'Deslizamiento (Slippage) significa la diferencia entre lo que espera obtener y lo que realmente obtiene es debido a que otros ejecutan primero',
  slippageCopy:
    'Deslizamiento (Slippage) significa la diferencia entre lo que espera obtener y lo que realmente obtiene debido a que otros ejecutan primero',
  depositCopy:
    'Esto deposita sus tokens seleccionados en el intercambio de <br> para intercambiar o agregar a un grupo de liquidez',
  nearDepositCopy:
    'Esto primero envuelve y luego deposita su Ⓝ en el intercambio de <br> para intercambiar o agregar a un grupo de liquidez',
  nearWithdrawCopy:
    'Esto primero desenvolverá su Ⓝ, luego lo retirará de <br> el intercambio y moverá los tokens a su billetera',
  withdrawCopy:
    'Esto retirará sus tokens seleccionados de <br> el intercambio y los depositará en su billetera',
  registerToken:
    'Esto registra un nuevo token con el intercambio de <br> que aún no figura en la lista',
  whitelistTokenCopy: 'Añade cualquier NEP-141 token',
  addLiquidityPoolCopy:
    'Esto crea un nuevo Pool de liquidez para los dos tokens seleccionados. La tarifa es el porcentaje que el Pool toma de cada transacción.',
  swapCopy:
    'Swap intercambia el primer token seleccionado con el segundo <br> token seleccionado. Se utilizarán los Pools con la liquidez más alta disponible y la tarifa de intercambio más baja',
  addTokenCopy: 'Añade cualquier NEP-141 token',
  getLPTokenCopy: 'Haga clic aquí para ir al Pool correspondiente',
  airdropCopy:
    'Solicite su Airdrop al menos una vez antes de la fecha de vencimiento; de lo contrario, su saldo se donará a la tesorería',
  farmRewardsCopy: 'Valor indicativo basado en precios y no en ejecución real',
  totalValueLockedCopy: 'Valor total Bloqueado',
  topPoolsCopy: 'Para cada par, solo muestra el TVL más alto del grupo',
  you_do_not_have_enough: 'no tienes suficientes fondos',
  must_provide_at_least_one_token_for:
    'Debe proporcionar un token superior a 0 para',
  is_not_exist: 'no existe',
  must_input_a_value_greater_than_zero: 'Must input a value greater than 0',
  amount_must_be_not_greater_your_balance:
    'El monto no debe ser mayor que su saldo',

  no_pool_available_to_make_a_swap_from:
    'No hay Pool disponible para hacer un intercambio',
  for_the_amount: 'Por la cantidad',
  no_pool_eng_for_chinese: ' ',
  is_not_a_valid_swap_amount: 'No es un monto válido para hacer Swap',
  not_nep_address:
    'La dirección que ingresó no es una dirección NEP-141, verifíquela e ingrésela nuevamente. Si se trata de otro contrato en red principal (mainnet), vaya a Rainbow Bridge para el procesamiento de puente',
  not_correct_address:
    'La dirección que ingresó es incorrecta, verifique e ingrese',
  popular_tokens: 'Tokens comunes',
  asset_label: 'Activos',
  account_label: 'Cuenta',
  total_label: 'Total',
  top_pools: 'Top pools',
  pool_id: 'Pool ID',
  more_pools: 'Más pools',
  my_watchlist: 'Mi lista de interés a revisar',
  h24_volume: '24h volumen',
  volume: 'Volumen',
  hide_low_tvl_pools: 'Ocultar Pools de TVL bajos,',
  hide_low_tvl_pools_mobile: 'Ocultar TVL bajos',
  watchlist_title: 'Mi lista de interés en la parte superior',
  add_watchlist: 'Añadir una lista de interés',
  remove_watchlist: 'Remover lista de interés',
  my_watchlist_copy: 'Las Pools seleccionadas aparecerán aquí',
  search_token: 'Buscar token',
  language: 'Idioma',
  deposit_to_add_liquidity: 'Depositar para agregar Liquidez',
  slip_warn: 'Tenga cuidado, por favor marque el mínimo que puede recibir',
  slip_invalid: 'La tolerancia de deslizamiento (slippage) no es válida',
  input_to_search: 'Entrada para buscar',
  click_search_bar_to_search: 'Haga clic en la barra de búsqueda para buscar',
  reward_tokens: 'Recompensa de tokens',
  detail_tip: 'Detalle',
  no_data: 'No hay Data',
  stable_swap: 'StableSwap',
  StableSwap: 'StableSwap',
  exchange_rate: 'Tarifa de Intercambio',
  including_fees: '(incluye las tarifas)',
  standard_gas: 'Standard Gas tarifa:',
  rst_token: 'Tu RST token',
  remove_tip: 'No hay comisión en la eliminación de liquidez en esta acción',
  share_liquidity: 'Cuota de liquidez',
  remove_token_confirm: 'Eliminarás el token RUST',
  remove_as_one_token: 'Eliminar como un token',
  flexible_tip: 'Quita cuanto quieras por token',
  add_type_all: 'Agregue todas los tokens en una proporción equilibrada',
  add_type_max: 'Use la cantidad máxima de tokens disponibles',
  select_balance: 'Seleccionar saldo',
  selectBalanceCopy:
    'Seleccione [NEAR wallet] para intercambiar directamente tokens disponibles en su NEAR wallet',
  near_wallet: 'NEAR wallet',
  ref_account: 'Cuenta REF',
  swap_tip:
    'Swap desde su cuenta REF seleccionando la cuenta REF en la configuración',
  attention: 'Atención',
  live: 'En vivo',
  ended_search: 'Terminada',
  my_farms: 'Tus Farms',
  staked_only: 'Staked',
  sort_by: 'Ordenada por ',
  your_farms_rewards: 'Tus recompensas de los Farms',
  your_farms: 'Tus farms',
  new: 'Más reciente',
  unstake_tip_t: 'tienes recompensas sin reclamar....',
  unstake_tip_m:
    'Unstaking eliminará la participación en todas las farms activas y pendientes del mismo par.',
  unstake_tip_b: '¿Quieres dejar de participar (unstake)?',
  value_rewards: 'Reclamar las recompensas',
  stake_unstake_tip:
    'Staking o Unstaking eliminará la participación de todas las farms activas y pendientes del mismo par.',
  over_tip:
    'Para optimizar la tarifa de gas, puede retirar hasta 4 tokens a la vez',
  no_token_tip: 'Sin token de recompensa',
  getToken_tip:
    '¡Puede reclamar sus recompensas o apostar sus tokens LP ahora!',
  in_farm: 'en Farm',
  swap_rate_including_fee: 'Tasa de swap (incluidas las comisiones)',
  by_share: 'Por Share',
  by_token: 'Por Token',
  your_positions_will_be_displayed_here:
    'Su(s) posición(es) se mostrará(n) aquí',
  price_impact: 'Impacto en el Precio',
  shares_left: 'Shares left',
  total_stable_coins: 'Total de stablecoins',
  daily_volume: 'Volumen diario',
  liquidity_utilisation: 'Utilización de liquidez',
  token_reserves: 'Stats',
  roi_calculator: 'Calculadora ROI ',
  lp_staked: 'LP Tokens Staked',
  usd: 'USD',
  lp_token: 'LP Tokens',
  stake_for: 'Duración',
  day_1: '1D',
  day_2: '7D',
  day_3: '30D',
  day_4: '90D',
  day_5: '1Y',
  cur_apr: 'ROI',
  reward_token: 'Recompensa de tokens',
  get_lp_token: 'Aduiere LP Tokens',
  calculate_roi: 'Calculadora ROI',
  rewards_claimed: 'Recompensas reclamadas',
  value_rewards_token: 'Valor de las recompensas',
  all: 'Todo',
  all_5: 'Maximo 4 tokens a la vez',
  insufficient_shares: 'Insufficient shares',
  shares_removed: 'Shares',
  shares_avaliable: 'Shares disponibles',
  minimum_shares: 'Minimum shares',
  shares_tip: 'Shares disponibles / Total de shares',
  stablecoin_only: 'Solo Stablecoin',
  more_than: 'Ingrese un número mayor o igual a 0.01',
  less_than: 'Por favor ingrese un número menor a 20',
  no_valid: 'Por favor ingrese un número válido',
  lp_fee: 'Tarifa del LP',
  protocol_fee: 'Tarifa del Protocol',
  referral_fee: 'Tarifa de referencia',
  protocol_fee_referral_fee: 'Tarifa de protocolo y tarifa de referencia',
  total_fee_create: 'Total de las tarifas',
  xref_title: 'GANE MÁS POR',
  xref_title1: 'Gane más',
  xref_title2: 'al hacer staking REF por xREF',
  xref_introdution:
    'Al hacer staking REF, tiene la oportunidad de ganar tarifas generadas por el protocolo. Cualquier titular de REF puede tener una participación en los ingresos obtenidos por Ref Finance.',
  staking_apr: 'Staking APR',
  view_stats: 'Ver Stats',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed: 'La entrada debe ser mayor o igual a 0.000001',
  more_than_stable_seed: 'La entrada debe ser mayor o igual a 1',
  are_you_sure: 'Está usted seguro?',
  price_impact_is_about: 'El impacto en el  precio se trata de',
  yes_swap: 'Sí, swap',
  amount_must_be_greater_than_0: 'la cantidad debe ser mayor que 0',
  volume_ratio: '24h Volumen/ Relación de Liquidez',
  go_to_near_wallet: 'Ir a la NEAR Wallet',
  into_ref_account: 'a tu cuenta REF.',
  deposit_into_ref_account: 'a tu cuenta REF para añadir liquidez',
  deposit_near_tip:
    'Para depositar, mantenga al menos 1 CERCA para cubrir la tarifa de gas',
  input_greater_than_available_shares:
    'Entrada mayor que las acciones disponibles',
  number_of_unique_stakers: 'Número de participantes únicos',
  total_ref_staked: 'Total de  REF Staked',
  total_xref_minted: 'Total de xREF Minted',
  protocol_projected_revenue: 'Ganancias compartidas con los xREF Holders',
  total_fee_Revenue_shared_with_xref_holders:
    'Total de las cuotas compartidas con los xREF Holders',
  provision_treasury: 'Provisión para la Tesorería',
  protocol_projected_revenue_tip:
    'Este número corresponde a los ingresos acumulados de la tarifa de negociación compartida para los xREF holders.Es igual al 75% de los ingresos totales por tarifas de la plataforma. Será diferente del monto real de la recompra del token REF debido a las fluctuaciones de precios.',
  stake_ref_to_xref_for_earning_more: '¡Stake REF a xREF para ganar más!',
  auto_router: 'Enrutador automático',
  auto_router_detail_sub_1: 'Esta ruta optimiza su swap por',
  auto_router_detail_sub_2: 'considerando todos los pools para el mismo par.',
  auto_router_detail_sub_3: 'Detalles:',
  introducing_parallel_swap: 'Introduciendo el Swap Paralelo',
  at_least: 'Al menos',
  xref_start_time: 'Empieza a la 1am UTC, Enero 18 del 2022.',
  xref_to_receive: 'xREF para recibir',
  ref_to_receive: 'REF para recibir',
  more_expensive_than_best_rate_en: 'Más caro que la mejor tarifa',
  optimal_path_found_by_our_solution:
    'Ruta óptima encontrada por nuestra solución',
  check_to_stable_banner: 'Verificar',
  rates_to_stable_banner: 'Tarifas',
  make_sure_you_understand_what_you_do: 'Asegúrate de entender lo que haces',
  go_to_your_account_to: 'Ve a tu cuenta para',
  deposit_l: 'depositar',
  withdraw_l: 'retirar',
  got_it: 'Entendido',
  check_the_transaction_settings:
    'Verifique la configuración de la transacción',
  filter_by: 'Filtrar por',
  allOption: 'Todo',
  stablecoin: 'Stable',
  near_ecosystem: 'Near-eco',
  bridged_tokens: 'Bridged',
  gaming: 'Gaming',
  nft: 'NFT',
  bridge: 'Bridge to/from',
  from_ethereum: 'Ethereum',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
  no_result: 'Lo sentimos, no pudimos encontrar ninguna farm',
  multiple: 'Múltiple',
  default: 'Por defecto',
  vi_go_live: 'Vietnamese va en vivo!',
  do_not_need_to_deposit_in_ref: 'Ya no necesita depositar en Ref Finance!',
  you_can_add_liquidity_directly:
    'Ahora puede agregar liquidez directamente usando su billetera NEAR.',
  do_not_need_deposit_anymore: 'Ya no necesita depositar en Ref Finance!',
  lightning_swaps: 'Swap relámpagos (Lightning Swaps)',
  frictionless_add_remove_liquidity:
    'Acciones simples para Añadir/Remover Liquidez',
  create: 'Crear',
  select_a: 'Seleccione un',
  wallet: 'wallet',
  to_use_ref_finance: 'usar Ref.Finance',
  not_supported: 'no suportado',
  installed: 'instalado',
  installe_now: 'instalar ahora',
  extension: 'extensión',
  first_time_using_ref: 'Primera vez usando Ref',
  learn_more: 'Aprenda más',
  install_sender_now: 'Instalar remitente ahora',
  connect_to_dapps_with_one_click: 'Conéctese a un dApps con un clic',
  install: 'instalar',
  Connecting: 'Conectando',
  check_sender_wallet_extension:
    'Verifique la extensión de la billetera del remitente',
  having_trouble: 'Tienes algún problema?',
  go_back: 'Regresa',
  web: 'web',
  Type: 'Tipo',
  click_to_view: 'Click para ver',
  transaction_failed: 'Transacción fallida',
  wallet_vi: ' ',
  value: 'Valor',
  usd_value: 'Valor USD',
  sauce_note:
    'SAUCE está diseñado para fondos (pools) de liquidez con activos fijos, ofreciendo precios óptimos.',
  support_ledger: 'Soporte Ledger',
  initialize_account_tip: 'Inicializa tu cuenta.',
  support_ledger_tip:
    "Por diseño, Ledger no puede manejar grandes transacciones (i.e. Auto Router: operar en varias pools a la vez) debido a su limitación de memoria. Cuando está activada, la opción 'Support Ledger' limitará las transacciones a su forma más simple (en detrimento de los precios óptimos potenciales encontrados por nuestro enrutador automático), por lo que se pueden firmar transacciones de un tamaño razonable..",
  start: 'Empezar',
  aprTip:
    'Valor indicativo basado en todas las recompensas (pendientes y en curso)',
  half: 'Mitad',
  max: 'Máximo',
  ref_account_balance_tip:
    'Parece que ocurrió un error al agregar/quitar liquidez al pool',
  ref_account_tip_2: 'Tienes token(s) en tu cuenta REF',
  ref_account_tip_top:
    'Tu(s) token(s) puede estar ahora en su cuenta interna REF',
  click_here: 'Dar Click aquí',
  to_recover_them: 'Para recuperarlos',
  ref_account_tip_3:
    'Para retirar tokens de su cuenta REF a su billetera NEAR, seleccione y retire',
  aurora_account_tip_4:
    'Para retirar tokens de su Mapping Account a su billetera NEAR, seleccione y retire',
  pool_fee_cross_swap: 'Tarifa Pool/Cross-chain',
  mapping_account: 'Mapping Account',
  mapping_account_tip: 'Tiene token(s) en su  Mapping Account',
  cumulative_ref_buyback: 'Acumulado REF Buyback',
  yearly_revenue_booster: 'Impulsor de ingresos anuales',
  Confirm: 'Confirme',
  Request_for_Quote: 'Requerimiento para cotizar (Quote)',
  mapping_account_explanation:
    "Una mapping address es una 'proxy address', que asigna la cuenta NEAR del usuario con una dirección ETH correspondiente en Aurora.",
  buy: 'Comprar',
  sell: 'Vender',
  buy_nsn: 'Comprar USN',
  buy_nsn_tip: 'USN es el stablecoin nativo respaldado por el Procolo NEAR',
  usn_tip_one: 'USN te permite operar en REF. Asegúrate que ',
  rate: 'Tarifa',
  name: 'Nombre',
  diff: 'Diff',
  best: 'Mejor',
  trading_fee: 'Tarifa por la transacción',
  usn_successful_click_to_view: 'Transacción exitosa. Para ver dar Click',
  awesomeNear_verified_token: 'AwesomeNear Token verificado',
  usn_fee_tip:
    'Tarifa cero cargada por Ref, los usuarios solo pagan tarifa USN!',
  total_bitcoins: 'Total de Bitcoins',
  total_usd_value: 'Total del valor USD',
  bitcoin_value: 'Valor del Bitcoin',
  stable_coin_value: 'Valor del StableCoin',
  pool_fee_apr: 'Tarifa del Pool por APY',
  ref_account_balance_tip_mobile: 'Tiene tokens en su cuenta REF.',
  click: 'Dar Click',
  to_recover: 'para recuperar.',
  rewards_week: 'Recompensas/semana',
  rewards: 'Recompensas',
  claimed_Rewards: 'Recompensas reclamadas',
  POOL: 'POOL',
  STAKE: 'STAKE',
  lp_Token: 'LP Tokens',
  booster: 'Booster',
  slash_policy: 'Política de Slash',
  stake_period: 'Perídi de Stake',
  append: 'Añadir (Adjuntar)',
  append_staking: 'Añadir Staking',
  unstake_time: 'Tiempo para hacer el Unstake',
  farm: 'Farm',
  reward_Token: 'Token de recompensa',
  status: 'Status',
  value_of_rewards: 'Valor de las recompensas',
  Rewards: 'Recompensas',
  free: 'Gratuito',
  lp_tokens: 'LP tokens',
  connect_to_wallet: 'Conectarse a la Wallet',
  anytime: 'En cualquier momento',
  slash_policy_content:
    'Cada seed tiene su propia slash rate. Y disminuirá linealmente con el tiempo hasta que sea 0 en el momento de desbloqueo de esta cuenta de CD.',
  not_expired: 'no caducado',
  accept_pay_slash_tip: 'Acepto pagar el slash',
  i_will_pay: 'Yo pagaré',
  lp_token_slash: 'Tarifa de salida del token LP.',
  booster_change_reason: 'El Booster cambiará en función del appending time',
  initial_booster: 'Booster Inicial',
  append_timing: 'Append timing',
  cd_limit: 'más de 16 cd (límite de la cuenta)',
  expired: 'Caducado',
  all_5_v2: '5 tokens/tiempo',
  all_4_v2: '4 tokens/tiempo',

  more_than_seed: 'La entrada debe ser mayor o igual que ',
  how_to_earn_more: 'Cómo combinar las recompensas?',
  you_shares: 'Tus Shares',
  lock_your_lp_tokens_with_booster:
    'Bloquea tus LP tokens para que puedas hacer un boost a tus recompensas',
  unstaked_anytime_no_booster:
    'En cualquier momento puedes hacer Unstaked, no se realiza el  booster',
  ordinary_stake: 'Stake ordinario',
  locking_stake: 'Mantener el staking',
  change_to_lock: 'Cambiar para mantener el staking',
  estimated_rewards: 'Recompensas estimadas',
  final_booster: 'Booster Final',
  existing_amount: 'Cantidad exitente',
  append_amount: 'Anexar cantidad',
  will_be_able_to_unstaked_after:
    'estará disponible para después hacer el unstaked',
  expected_reward: 'Recompensa esperada',
  you_have: 'Tú tienes',
  tokens_small: 'tokens',
  reward_apr: 'Recompensa por APR',
  price_board: 'Tablero de precios',
  close: 'Cerrar',
  stake_min_deposit: 'La entrada debe ser mayor o igual que ',
  unlock: 'Unlock',
  exit_fee: 'Tarifa por salir',
  end_locking_period: 'Fin del peródo de bloqueo',
  add_lp_tokens_tip:
    'Necesita tokens LP para hacer el pool en el farm  correspondiente. Primero agregue liquidez al pool para obtener tokens LP.',
  slash_tip:
    'Tarifa de salida = Tasa de tarifa de salida * Fin del período de bloqueo * Cantidad a desbloqueo',

  nonBoosted: 'No-boosted',
  exit_fee_tip:
    'Cantidad estimada que pagaría para romper su período de bloqueo',
  forbiddenTip: 'Este farm no admite esta opción de duración',
  unstakeTip:
    'Hacer Staking o unstaking automáticamente reclamará sus recompensas.',
  endedFarmsUnstakeTip:
    'Hacer Unstaking eliminará la participación de todas las farms activas y pendientes del mismo par. ',
  comimg: 'Próximamente',
  appendTip: 'No puede ser anterior a la hora de bloqueo actual',
  week: 'semana',
  yours: 'Suyos',
  boost: 'Boost',
  near: 'NEAR',
  eth: 'ETH',
  others: 'otros',
  stakeLove: 'Stake LOVE',
  unstakeLove: 'Unstake LOVE',
  get_love: 'Obtén LOVE',
  swap_successful: 'Swap realizado con éxito. ',
  trading_successfull: 'Intercambio exitoso. ',
  total_near_amount: 'Cantidad Total de NEAR',
  near_value: 'Valor de NEAR',
  borrow: 'Borrow',
  burrow_usn_tip: 'Puedes pedir un préstamo de USN en Burrow.',
  migrate: 'Migrar',
  go_to_new_farm: 'Ir a New Farms',
  voting_gauge_share_tip:
    'La proporción del REF utilizado para la asignación de votos a la recompensa total del REF en este período.',
  swap_capital: 'SWAP',
  farm_capital: 'FARM',
  sauce_capital: 'SAUCE',
  dao_capital: 'DAO',
  available_to_stake: 'disponible para hacer  stake',
  recent_one_mounth_activity: 'Actividades realizadas en el mes',
  your_assets: 'Tus activos',
  connect_wallet: 'Conectarse a la Wallet',
  disconnect: 'Desconectar',
  change: 'Cambiar',
  wallets_below_supports_on_PC:
    'Las Wallets listadas pueden ser soportadas en una PC',
  connect: 'Conectar',
  make_sure_device_plugged_in: 'Asegúrate de que tu dispositivo esté conectado',
  then_enter_an_account_id_to_connect:
    'luego ingrese una ID de cuenta para conectarse',
  veTip:
    'Los veLP tokens no son transferibles, los veLPT representan su poder de voto ',
  loveTip:
    'LOVE significa Liquidez de veToken. LOVE, como cualquier token NEP-141, es transferible y se puede intercambiar, agrupar, hacer pool y farm. LOVE representa la liquidez subyacente de sus veTokens',

  lock_your_lp_tokens: 'Bloquear los  LP Tokens',
  unlock_your_defi_power: 'Desbloquer tu  DeFi Power',
  unlock_tip_mobile: 'El desbloqueo está disponible ahora!',
  unlock_tip_top:
    'Su bloqueo ha caducado, ¡el desbloqueo está disponible ahora!',
  bonus_to_be_withdraw: 'bono para ser retirado',
  keep_ve: 'mantener',
  months: 'meses',
  month: 'mes',
  lock_lp_tokens: 'Bloquear LP Tokens',
  get_lptoken: 'Get LP Tokens',
  duration: 'Duration',
  ve_lock_tip: 'No puede ser anterior a la fecha de desbloqueo actual',
  get: 'Obtener',
  locking_ve: 'Bloqueando',
  in_addition_to_the: 'Además de ',
  already_locked: 'ya bloqueado',
  unlocking_is: 'Desbloquear es',
  you_have_no_lp_share: 'No tienes LP Tokens',
  lock: 'Bloquear',
  unlock_lptoken: 'Desbloquear LP Tokens',
  locked: 'Bloqueado',
  available: 'Disponible',
  balance_lowercase: 'balance',
  voting_power: 'Voting Power',
  lock_lp_tokens_first: 'Primero bloquea los LP Tokens!',
  farm_booster: 'Farm Booster',
  go_to_farm: 'Ir a la Farm',
  in: 'en',
  more_lowercase: 'más',
  avaliable_to_lock: 'Disponible para bloquear',
  lock_lptoken: 'Bloquear LP Tokens',
  lptoken_locker: 'Bloqueador de LP Token Locker',
  filter: 'Filtro',
  bonus: 'Bono',
  your_shares_ve: 'Tus Shares',
  voted: 'Votados',
  my_voting_power: 'Mi poder para Votar',
  new_voting_ratio: 'Nuevo índice de votación',
  REF_allocation: 'Asignación de REF',
  vote: 'Votados',
  preview: 'Vista previa',
  options: 'opciones',
  ratio: 'Relación',
  forum_discussion: 'Foro de  Discusión',
  voted_veLPT: 'veLPT votados',
  your_contribution: 'Tu contribución',
  delete: 'Borrar',
  not_start: 'No comenzar',
  no_veLPT: 'No veLPT',
  claim_bonus: 'Reclamar Bono',
  ended_ve: 'Terminado',
  proposal_details: 'Detalles de la propuesta',
  back: 'Regresar',
  pending_ve: 'Calentando',
  you_voted: 'Tu votaste',
  add_bonus: 'Agregar bono',
  you_vote: 'Tu voto',
  Creator: 'Creador',
  turn_out: 'Apagar',
  top_answer: 'respuesta superior',
  your_bonus: 'Tus bonuos',
  gauge_weight_vote: 'Medidor del Peso del  Voto',
  governance: 'Governancia',
  confirm: 'Confirme',
  voting_period: 'Periodo de Votación',
  applying_period: 'Período de aplicación',
  community_gauge_share: 'Medidor del Share de laCommunidad',
  qualified_pools: 'Pools calificadas',
  total: 'Total',
  supply_voted: 'Suministro votado',
  proposed: 'Propuesto',
  farm_reward: 'Recompensa del farm',
  check_last_round: 'Revisar la última ronda',
  last_round_voting_result: 'Resultado de la última ronda de votación',
  create_proposal: 'Crear propuesta',
  type: 'Tipo',
  add_option: 'Añadir Opción',
  lock_lp_terms:
    'Entiendo que no podré remover mis tokens LP durante todo el período de bloqueo acordado.',
  connect_to_near_wallet: 'Conectar a la Wallet de NEAR ',
  only_for_whitelist_address: 'Solo para la dirección de la whitelist',
  v1Legacy: 'Legacy',
  v2New: 'Classic-New',
  v2_new_farms: 'Classic farms update',
  migrate_now: 'Migrar ahora!',
  v2_boost_tip:
    'Las farms Classic admitirán boosted farms. Las farms Legacy se quedarán sin recompensas el <span class="font-bold">1 de agosto de 2022</span>.',
  v2_boost_tip2: 'Las Classic farms soportan las  boosted farms.',
  v2_boost_no_tip:
    'Las farms Legacy se quedarán sin recompensas el <span class="font-bold">1 de agosto de 2022</span>..',
  no_farm_need_to_migrate: 'Ninguna farms necesita migrar',
  v1_farms: 'Legacy Farms',
  has_rewards_tip:
    'Has reclamado recompensas en <a rel="noopener noreferrer nofollow" class="text-green Color text-based cursor-pointer underline hover:text-sender Hot" href="/farms" >Legacy Farms</a>. Puede retirarlos en cualquier momento.</div>',
  love: 'LOVE',
  Available_to_stake: 'Disponibe para hacer stake',
  you_staked: 'Ha hecho un stake',
  has_rewards_tip_in_v2:
    'Retire las recompensas heredadas de <a rel="noopener noreferrer nofollow"  href="/farms" class="text-sm text-greenColor cursor-pointer underline ml-1 hover:text-senderHot"> Legacy Farms</a>.',
  how_to_get: 'Cómo obtener?',
  get_booster: 'Obtener Booster',
  ended_farms: 'Farms finalizadas',
  boosted_farms: 'Boosted farms',
  your_farm: 'Tus farm',
  your_boosted_farms: 'Tus boosted farms',
  search_farms_by_token: 'Buscar farms por token',
  search_farms: 'Buscar farms',
  more_lock_ve_popup: 'más ',
  more_lock_ve_popup2: 'más',
  more_in: 'más en',
  starts: 'empieza',
  left: 'dejado (left)',
  proposal_title: 'Título de la propuesta',
  share_forum_discussion_link_here:
    'Compartir enlace del foro de discusión  aquí',
  required_field: 'Campo Requerido',
  voting_power_and_extra_earnings: 'Poder de Voto  & ganancias adicionales',
  bonus_tip:
    'El bono de votación está diseñado para animar a los usuarios a votar. Su bono depende de su número de acciones',
  yes_continue: 'Sí, continuar',
  unlocking_tip:
    'El desbloqueo de tokens LP cancelará automáticamente su voto (y la bonificación asociada) en las siguientes propuestas',
  create_pool: 'Crear un Pool',
  search_by_token: 'Buscar por token',
  your_power: 'Tu Power',
  farm_has_boost_tip:
    'Tu Power = Tud staked LP Tokens * booster (por staking LOVE)',
  farm_no_boost_tip: 'Tu Power = Tu staked LP Tokens',
  more_than_a_simple_pool: 'Más que una simple pool',
  ended_Farms: 'Farms finalizadas ',
  vote_capital: 'VOTAR',
  starting_time: 'Tiempo de inicio',
  ending_time: 'Tiemo de finalización',
  please_choose_voting_duration: 'Elija la duración de la votación',
  please_choose_proposal_voting_duration:
    'Elija la duración de la votación de la propuesta',
  days_ve: 'días',
  vote_mobile_capital: 'VOTAR',
  risks_capital: 'RIESGOS',
  view_on: 'Ver en',
  boostFarmTip: 'Obtener booster haciendo staking en LOVE',
  boosted: 'Boosted',
  love_staked: 'Staked LOVE',
  your_apr: 'Tu APR',
  range_apr: 'Rango o referencia APR',
  no_pair_found: 'No se encontró ningún par',
  found_stable_pool_in_sauce: 'Pool stable pool en SAUCE',
  lp_tokens_locking: ' LP Tokens ',
  you_currently_have: 'Tu Actualmente tienes ',
  scheduled_to_be_unlocked: 'Programado para ser desbloqueado',
  locking_more_lp_tokens: 'Bloquear más LP Tokens ',
  will_mean_these: 'significará esto ',
  lp_tokens_will_not_be_unlocked_until:
    ' Los tokens LP no se desbloquearán hasta',
  Entiendo_que_no_podré_quitar_mi: 'Entiendo_que_no_podré_quitar_mi ',
  until: 'hasta que',
  continue: 'Continuar',
  then_enter_a_derivation_path_to_connect:
    'luego ingrese una ruta de derivación para conectarse',
  account_identification_failed:
    '¡Error en la identificación de la cuenta! Proporcione manualmente',
  input_account_id: 'Ingrese la identificación de la cuenta (ID)',
  we_found: 'Nosotras la encontramos ',
  accounts_on_your_device: 'cuentas en tu dispositivo',
  select_the_accounts_you_wish_to_connect:
    'Seleccione la cuenta que desea conectar',
  can_not_find_any_accounts:
    'No se puede encontrar ninguna cuenta asociada con este Ledger. Cree una nueva cuenta NEAR en',
  or_connect_another_ledger: 'or connect an another Ledger',
  add: 'Aãdir',
  finish: 'Finalizar',
  add_custom_network: 'Agregar red personalizada',
  network_name: 'Nombre de red',
  rpc_name_taken_tip: 'El nombre de la red ya estaba tomado',
  network_invalid: 'La red no era válida',
  fobidden_testnet_rpc_tip:
    'La red del servidor RPC (testnet) es diferente con esta red (mainnet)',
  no_support_testnet_rpc_tip: 'Testnet no admite agregar una RPC personalizado',
  farm_only: 'Solamnte Farm',
  please_confirm_this_transaction_on_ledger:
    'Confirme esta transacción en Ledger',
  newText: 'Nuevo',
  near_validation_error: 'Must have 0.5N or more left in wallet for gas fee.',
  no_positons_in_this_pool_yet: 'No positons in this pool yet',
  no_claimed_rewards_yet: 'Recompensas por reclamar',
  out_of: 'de',
  value_must_be_less_than_or_equal_to: 'Value must be less than or equal to',
  trade_capital: 'COMERCIO',
  trade_capital_hump: 'comercio',
  pro: 'Pro',
  limit: 'Limite',
  make_an_order: 'Hacer un pedido',
  your_active_orders_will_appear_here: 'Sus pedidos activos apareceran aqui',
  your_history_orders_will_appear_here:
    'Sus historial de ordenes apareceran aqui',
  liquidity_capital: 'LIQUIDEZ',
  goes_to_limit_order: 'pasa a dorden limitada.',
  limit_order: 'Orden de limite',
  farm_stop_tip: 'Esta granja ha sido detenida.',
  pool_stop_tip: 'Este pool ha sido detenido.',
  volume_24h: 'Volume (24h)',
  add_to_watchlist: 'Anadir a la lista de vigilancia',
  remove_from_watchlist: 'Eliminar de la lista de vigilancia',
  create_order: 'Crear orden',
  filled: 'Llenado',
  canceled: 'Cancelado',
  partially_filled: 'Parcialmente lleno',
  pool_composition: 'Composicion del grupo',
  amount: 'Importe',
  h24_volume_bracket: 'Volume(24h)',
  fee_24h: 'Tasa(24h)',
  cross_chain_swap: 'Intercambio en cadena',
  orders: 'Ordenes',
  your_orders: 'Tus ordenes',
  active: 'Activo',
  history: 'Historia',
  you_sell: 'Tu venta',
  you_buy: 'Tu compra',
  fee_tiers: 'Niveles de tarifas',
  order_rates: 'Tarifas de los pedidos',
  created: 'Creado',
  executed: 'Ejecutado',
  actions: 'Acciones',
  claimed_upper: 'Reclamado',
  open_my_order: 'abierto',
  initial_order: 'Orden inicial',
  this_order_has_been_partially_filled:
    'Este pedido ha sido parcialmente ejecutado',
  filled_via_swap: 'Llenado a traves de un intercambio',
  state: 'Estado',
  partially: 'Parcialmente',
  select_tokens: 'Selecionar Tokens',
  fee_Tiers: 'Niveles de tasas',
  select_s: 'seleccione',
  no_pool: 'No hay pool',
  input_amount: 'Introduzca el importe',
  update_range: 'Actualizar Rango',
  not_enough_balance: 'Saldo insuficiente',
  set_price_range: 'Establecer rango de precios',
  current_price: 'Precio actual',
  pool_creation: 'Creacion del pool',
  pool_creation_tip:
    'No existe un pool para las fichas seleccionadas. Para crear el pool, hay que establecer la tarifa del pool, proporcionando las cantidades correspondientes.',
  starting_price: 'Precio inicial',
  create_a_pool: 'Crear Pool',
  add_single_price_tip:
    'Su posicion no devengara comisiones ni se utilizara en las operaciones hasta que el precio del mercado entre en su rango.',
  invalid_range_tip:
    'Rango seleccionado no valido. El precio minimo debe ser inferior al precio maximo.',
  maket_price_outside_tip: 'The maket price is outside your price range.',
  maket_price_outside_single_only_tip:
    'El precio del mercado esta fuera de su rango de precios.',
  min_price: 'Precio minimo',
  max_price: 'Precio maximo',
  min: 'Minimo',
  out_of_range: 'Fuera de rango',
  in_range: 'En el rango',
  unclaimed_fees: 'Tarifas no reclamadas',
  your_position_will_be: 'tu posicion sera',
  at_this_price: 'a este precio',
  price_range: 'Rango de precios',
  positions: 'posiciones',
  add_new_position: 'Agregar nueva posicion',
  range: 'Rango',
  unclaimed_fee: 'Tarifa no reclamada',
  login_risk_tip:
    'By checking this box and moving forward, you confirm that you fully understand the <a rel="noopener noreferrer nofollow" target="_blank" class="text-greenColor text-sm font-bold cursor-pointer hover:underline" href="/risks" ' +
    ')">risks</a> of using Ref Finance.',
  no_pool_tip: 'Oops! The pool does not exist.',
  total_ref_week: 'Total REF/week',
  ref_week_you_will_get: 'REF/week you will get',
  stable_pool: 'Stable Pool',
  market_rate: 'Market Rate',
  limit_price_tip:
    'To improve deal the efficiency, your price should be in one slot nearby automatically',
  no_token_found: 'No token found',
  search_name_or_address: 'Search name or paste address...',
  token_address_invalid: 'The token address was invalid',
  price_impact_warning: 'Price Impact Warning',
  confirm_order: 'Confirm Order',
  you_Sell: 'You Sell',
  to_Buy: 'to Buy',
  at_Price: 'at Price',
  insufficient_balance: 'Insufficient Balance',
  MORE: 'MORE',
  limitTip: 'Your price is automatically set to the closest price slot',
  v2PoolTip: 'You can have up to 16 positions in DCL pools',
  orderTip: 'You can have up to 16 active/history limit orders',
  v2_pools: 'DCL Pools',
  minimum_received_dot: 'Mínimo recibido',
  v2_your_pool_introduction: 'Your discretized concentrated liquidity',
  v1_your_pool_introduction: 'Your liquidity in classic pools',
  estimate_value: 'Estimate Value',
  your_investment: 'Your Investment',
  your_investment_tip:
    'USD value of your investment on Ref:Classic pools + DCL pools (including staked in farms) + xREF',
  classic_pools: 'Classic Pools',
  xref_staking: 'xREF Staking',
  token_balances: 'Token Balances',
  unclaimed_earnings: 'Unclaimed Earnings',
  unclaimed_earnings_tip:
    'USD value of unclaimed fees from DCL pools, and unclaimed farm rewards.',
  unclaimed_pool_fees: 'Unclaimed Pool Fees',
  unclaimed_farm_rewards: 'Unclaimed Farm Rewards',
  active_orders: 'Active Orders',
  yield_farming: 'Yield Farming',
  execute_status: 'Execute Status',
  your_liquidity_usd_value: 'Your Liquidity (USD value)',
  usage: 'Usage',
  holding: 'Holding',
  your_lp_tokens_and_shares: 'Your LP Tokens(Shares)',
  usd_value_staked: 'USD Value Staked',
  instant_swap: 'Instant Swap',
  swapped: 'Swapped',
  created_s: 'created',
  order_progress: 'Order Progress',
  dcl_pool: 'DCL Pool',
  classic: 'Classic',
  classic_farms: 'Classic Farms',
  unstaked: 'Unstaked',
  staked_in_farm: 'Staked in farm',
  farm_reward_range: 'Farm Reward Range',
  your_positions: 'Your Positions',
  your_price_range: 'Your Price Range',
  locked_in: 'locked in',
  executing: 'Executing',
  from_2: 'from',
  to_2: 'to',
  your_orders_2: 'Your Orders',
  in_farm_2: 'in farm',
  your_liquidity_2: 'Your Liquidity',
  legacy_tip: 'Found unstaked LP tokens or rewards in',
  legacy_farms: 'Legacy Farms',
  all_farms: 'All Farms',
  your_farms_2: 'Your Farms',
  dcl_farms: 'DCL Farms',
  farm_type: 'Farm type',
  farm_2: 'Farm',
  to_claim: 'To Claim',
  liquidity_staked: 'Liquidity staked',
  dcl_pool_detail: 'DCL Pool Detail',
  reward_range: 'Reward Range',
  reward_range_tip: 'Farm reward within this range',
  ending_soon: 'Ending soon',
  farm_ended_tip: 'This farm has ended.',
  farm_will_ended_tip: 'This farm will end soon.',
  new_farm: 'New Farm',
  is_coming: 'is coming',
  est_apr: 'Est. APR',
  state_2: 'State',
  farming: 'Farming',
  unavailable: 'Unavailable',
  liquidity_detail: 'Liquidity Detail',
  hide: 'Hide',
  show: 'Show',
  unavailable_positions: 'unavailable positions',
  your_price_range_tip: 'Your price range is out of reward range',
  position_has_staked_tip: 'This position has been staked in another farm',
  minimum_tip: 'The minimum staking amount is',
  your_liquidity_3: 'your liquidity',
  position_has_staked_pre: 'This position has been staked in',
  another_farm: 'another farm',
  add_position: 'Add Position',
  full_range: 'Full Range',
  your: 'Your',
  max_apr: 'Max.APR',
  faming_positions: 'Farming Positions',
  you_can_earn_tip: 'You can earn rewards by farming, est. APR is',
  you_can_earn_current_tip:
    'Your current staked farm ended, and new farm is coming, est. APR is',
  go_new_farm: 'Go New Farm',
  go_farm: 'Go Farm',
  earn_rewards: 'You can earn rewards',
  est_apr_is: 'est. APR is',
  new_farm_2: 'new farm',
  you_can_earn_current_pre_tip: 'Your current staked farm ended, and',
  in_ended_farm: 'in ended farm',
  staked: 'Staked',
  in_farm_3: 'in farm',
  estimation: 'Estimation Value',
  no_dcl_position_tip:
    "You don't have any DCL Liquidity position for now, click 'AddPosition' to start farming.",
  price_on_slot_tip: 'The price should be in one slot nearby.',
  position_will_appear_here: 'Your liquidity positions will appear here.',
  by_farming: 'by farming',
  this_staked_tip: 'This NFT has been staked',
  dcl_liquidity: 'DCL Liquidity',
  classic_liquidity: 'Classic Liquidity',
  will_appear_here_tip: 'Your liquidity positions will appear here.',
  dcl_will_appear_here_tip: 'Your DCL liquidity positions will appear here.',
  classic_will_appear_here_tip:
    'Your Classic liquidity positions will appear here.',
  yield_farming_appear_here_tip: 'Your yield farming will appear here.',
  active_order_appear_here_tip: 'Your active order(s) will appear here.',
  account_appear_here_tip: 'Your wallet/account assets will appear here.',
  farm_state: 'Farm State',
  farm_detail: 'Farm Detail',
  unstaked_2: 'Unstaked',
  unstaked_positions: ' Unstaked Positions',
  price_range_overlap_ratio: ' Price Range Overlap Ratio',
  swapped_history: 'swap history',
  swapped_history_order: 'Swapped',

  overview: 'Overview',
  positions_2: 'Positions',
  'deposit_near_tip_0.5': 'To deposit, keep at least 0.5 NEAR to cover gas fee',
  trade: 'Trade',
  earn_2: 'Earn',
  portfolio: 'Portfolio',
  more_2: 'More',
  liquidity_pools_2: 'Liquidity Pools',
  business_inquiries: 'Business Inquiries',
  orderbook_mobile: 'Orderbook',
  real_time_executed_orders: 'real-time executed orders',
  real_time_executed_orders_tip:
    'When placing a sell order above market price or a buy order below market price using limit order function, Ref will attempt to execute a swap-with-stop-point action first, and if there is any remaining amount, a limit order will be created to fill the rest.<br> Real-time executed orders refer to orders placed using the limit order function but executed through swapping. In the "History" section, you can view real-time executed orders between the earliest displayed limit order in History up to the present time.',

  ledger_guide_tip_1:
    'To successfully use your Ledger wallet for transactions, the ',

  ledger_guide_tip_2: 'feature has been automatically activated.',
  ledger_guide_tip_3: 'Please be aware',
  support_ledger_tip_new: '"Support Ledger"',

  ledger_guide_tip_4:
    'that due to Ledger wallet constraints, the current rate provided by the swap function',
  ledger_guide_tip_5: 'may not be the best price.',
  view_dcl_pool: 'View DCL Pool',
  your_trade_route: 'Your Trade Route',
  markets: 'Markets',
  exchanges: 'Exchanges',
  output_est: 'Output (est.)',
  go_to_orderbook: 'Go to Orderbook',
  new_swap_order_tip:
    'The price is from the Ref AMM offer and for reference only. There is no guarente that your limit order will be immediately filled. ',
  instants_swap: 'Instant Swap',
  swappped: 'Swapped',
  claimed: 'Claimed',
  executing_capital: 'Executing',
  record: 'Record',
  trade_route: 'Trade Route',
  settings: 'Settings',
  cross_chain_options: 'Cross-chain Options',
  no_trade_routes: 'No Trade Routes',
  steps_in_the_route: 'Steps in the Route',
  swap_chart_no_data: 'Not enough data for the chart right now.',
  last_updated: 'Last Updated',
  steps_in_the_route_zh: '',
  has_tri_pool_tip:
    ' in Ref. Enable Trisolaris in Cross-chain Options to find more.',

  Overview: 'Overview',
  RefFinance: 'Ref.finance',
  Orderly: 'Orderly',
  Burrow: 'Burrow',
  Supplied: 'Supplied',
  Borrowed: 'Borrowed',
  NetAPY: 'Net APY',
  UnclaimedRewards: 'Unclaimed Rewards',
  YouSupplied: 'You Supplied',
  SupplyMarket: 'Supply Market',
  YouBorrowed: 'You Borrowed',
  BorrowMarket: 'Borrow Market',
  NetAPYTip:
    'Net APY of all supply and borrow positions, including base APYs and incentives',
  Market: 'Market',
  TotalSupplied: 'Total Supplied',
  TotalBorrowed: 'Total Borrowed',
  AvailableLiquidities: 'Available Liquidity',
  DailyRewards: 'Daily Rewards',
  SupplyAPY: 'Supply APY',
  BorrowAPY: 'Borrow APY',
  Collateral: 'Collateral',
  Adjust: 'Adjust',
  Supply: 'Supply',
  Repay: 'Repay',
  Borrow: 'Borrow',
  HealthFactor: 'Health Factor',
  CollateralFactor: 'Collateral Factor',
  AdjustCollateral: 'Adjust Collateral',
  UseAsCollateral: 'Use as Collateral',
  RemainingCollateral: 'Remaining Collateral',
  RemainingBorrow: 'Remaining Borrow',
  NoSupplyTip: 'Your supplied assets will apear here.',
  NoBorrowTip: 'Your borrowed assets will apear here.',
  APY: 'APY',
  CF: 'C.F.',
  burrowErrorTip:
    'The current balance is below the minimum token decimals, so that it cannot be processed by the contract.',
  healthErrorTip:
    "Your health factor will be dangerously low and you're at risk of liquidation.",
  Summary: 'Summary',
  Welcome: 'Welcome!',
  ConnectView: 'Connect your wallet to view',
  TotalSuppliedMarket: 'Total Supplied',

  NetWorth: 'Net Worth',
  Claimable: 'Claimable',
  TotalDebts: 'Total Debt',
  WalletAssets: 'Wallet Assets',
  TotalInvested: 'Total Invested',
  TotalAssets: 'Total Assets',
  TokenAllocation: 'Token Allocation',
  bridge_pure: 'Bridge',
  netWorthTip:
    'Total value of investments in Ref (including claimable rewards) + Total value of Orderly assets + Total value of Burrow assets + Total assets in wallet',
};
export default Object.assign(es, es_in_risks_page);
